@import '@scss/_module';

.loader-fixed {
  z-index: 100;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.loader-inline {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}

.loader-title {
  color: $regentgray;
  margin-left: rem(11);
}