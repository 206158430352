@import '@scss/_module';

.nav_title {
  font-size: rem(14);
  letter-spacing: rem(0.171429);
}

.nav_icon {
  position: relative;
  vertical-align: top;
  width: rem(14) !important;
  height: rem(17) !important;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

.icon_dashboard {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.235 3.5c-4.14-1.256-7.92 1.654-7.92 5.42 0 .514.06 1.484.24 2.055 0 .114.12.228.24.228h10.98c.12 0 .24-.114.3-.228.12-.57.24-1.54.24-2.054 0-2.454-1.62-4.68-4.08-5.422zm-1.92.855c.36 0 .6.229.6.571 0 .342-.24.57-.6.57s-.6-.228-.6-.57c0-.342.24-.57.6-.57zm-4.2 5.136c-.36 0-.6-.228-.6-.57 0-.343.24-.571.6-.571s.6.228.6.57c0 .343-.24.571-.6.571zm1.68-2.967a.6.6 0 01-.84 0c-.24-.228-.24-.628-.06-.856a.6.6 0 01.84 0c.24.228.24.628.06.856zm2.52 3.538c-.66 0-1.26-.57-1.08-1.141.18-.571 1.08-2.283 1.08-2.283s.9 1.712 1.08 2.283c.18.57-.42 1.14-1.08 1.14zm3.42-3.538a.6.6 0 01-.84 0 .533.533 0 010-.8.6.6 0 01.84 0c.18.172.18.572 0 .8zm.78 2.967c-.36 0-.6-.228-.6-.57 0-.343.24-.571.6-.571s.6.228.6.57c0 .343-.24.571-.6.571z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}

.icon_time {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)' fill='%23#{$nav-icon-color}'%3E%3Cpath d='M11.074 3.964l.838-.838-.824-.825-.897.897a5.797 5.797 0 00-2.774-1.039V.964h1.166V-.203h-3.5V.964H6.25v1.195c-1 .1-1.955.458-2.774 1.039L2.579 2.3l-.825.825.838.838a5.833 5.833 0 108.482 0zm-4.24 8.666a4.667 4.667 0 110-9.333 4.667 4.667 0 010 9.333z'/%3E%3Cpath d='M6.833 4.464v3.5h-3.5a3.5 3.5 0 103.5-3.5z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23#{$nav-icon-color}' d='M0 0h14v14H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.icon_companies {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 14H11V12.7234H10.5925V1.93617L3.47185 0V12.7021H3V14ZM7.6756 3.59574H8.96247V5.25532H7.6756V3.59574ZM7.6756 6.55319H8.96247V8.21277H7.6756V6.55319ZM7.6756 9.51064H8.96247V11.1702H7.6756V9.51064ZM5.08043 3.59574H6.36729V5.25532H5.08043V3.59574ZM5.08043 6.55319H6.36729V8.21277H5.08043V6.55319ZM5.08043 9.51064H6.36729V11.1702H5.08043V9.51064Z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}

.icon_contacts {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.727 1a.781.781 0 00-.776.78v.856h-.136A.82.82 0 001 3.455a.82.82 0 00.815.818h.136v1.909h-.136A.82.82 0 001 7a.82.82 0 00.815.818h.136v1.91h-.136a.82.82 0 00-.815.818.82.82 0 00.815.818h.136v.852c-.003.43.35.784.776.784h8.496a.784.784 0 00.777-.784V1.78c0-.429-.35-.78-.777-.78H2.727zm-.912 2.182h.815c.155 0 .271.117.271.273a.266.266 0 01-.271.272h-.815a.266.266 0 01-.272-.272c0-.156.117-.273.272-.273zm5.16 0a1.637 1.637 0 010 3.273 1.637 1.637 0 010-3.273zm-5.16 3.545h.815c.155 0 .271.117.271.273a.266.266 0 01-.271.273h-.815A.266.266 0 011.543 7c0-.156.117-.273.272-.273zm4.006 0H8.13c.933 0 1.697.751 1.697 1.68v1.866c0 .143-.13.272-.271.273h-5.16a.283.283 0 01-.273-.273V8.406a1.69 1.69 0 011.698-1.679zm-4.006 3.546h.815c.155 0 .271.117.271.273a.266.266 0 01-.271.272h-.815a.266.266 0 01-.272-.272c0-.156.117-.273.272-.273z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}

.icon_projects {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.154 4.699a.685.685 0 00-.245.525v6.358c0 .757.613 1.37 1.37 1.37h7.441a1.37 1.37 0 001.37-1.37V5.224a.685.685 0 00-.244-.525L7.439 1.005a.685.685 0 00-.88 0L2.154 4.699zm4.311 5.784H4.376V8.395h2.09v2.088zm0-3.155H4.376v-2.09h2.09v2.09zm1.067-2.09h2.09v2.089h-2.09V5.238zm0 3.155h2.09v2.09h-2.09v-2.09z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}

.icon_expenses {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.681 2.395L7 0 1.318 2.395C.676 11.399 7 13.785 7 13.785s6.324-2.386 5.681-11.39zM7.541 9.38v.432c0 .11-.089.199-.198.199h-.795a.199.199 0 01-.198-.2V9.36c-.523-.12-1.005-.385-1.341-.74a.199.199 0 01.007-.281l.578-.546a.199.199 0 01.28.007c.245.26.697.44 1.099.44.327 0 .88-.09.88-.425.003-.303-.16-.414-.965-.658-.748-.226-1.88-.569-1.88-1.887 0-.751.51-1.336 1.342-1.556v-.476c0-.11.089-.198.198-.198h.795c.11 0 .198.089.198.198v.411c.433.06.852.207 1.17.413.093.06.119.182.06.274l-.432.668a.199.199 0 01-.274.059c-.23-.148-.617-.252-.943-.252-.342 0-.922.097-.922.459 0 .357.184.49 1.034.747.725.22 1.822.552 1.811 1.803 0 .79-.584 1.39-1.504 1.561z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}

.icon_referrals {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.892 5.875l-.76 4.848c-.044.275-.262.477-.518.477H3.386c-.256 0-.474-.201-.517-.477l-.761-4.848a.601.601 0 01.202-.557.487.487 0 01.545-.056L4.881 6.34l1.66-3.266c.185-.365.733-.365.918 0L9.12 6.34l2.027-1.078a.487.487 0 01.545.056c.157.13.235.345.201.557z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}

.icon_billing {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.74085 3.42578C1.98409 3.42578 1.37061 4.03926 1.37061 4.79602V5.73806H13.7027V4.79602C13.7027 4.03926 13.0893 3.42578 12.3325 3.42578H2.74085ZM1.36987 7.27834H13.702V10.5326C13.702 11.2894 13.0885 11.9029 12.3318 11.9029H2.74011C1.98335 11.9029 1.36987 11.2894 1.36987 10.5326V7.27834ZM2.9112 9.16239C2.9112 8.9732 3.06457 8.81983 3.25376 8.81983H7.19319C7.38238 8.81983 7.53575 8.9732 7.53575 9.16239V10.0188C7.53575 10.208 7.38238 10.3613 7.19319 10.3613H3.25376C3.06457 10.3613 2.9112 10.208 2.9112 10.0188V9.16239Z' fill='white'/%3E%3C/svg%3E");
}

.icon_subscriptions {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.536 1.146a.5.5 0 01.928 0l1.238 3.061a.5.5 0 00.428.312l3.294.231a.5.5 0 01.287.882l-2.53 2.123a.5.5 0 00-.163.504l.798 3.204a.5.5 0 01-.75.545L7.265 10.26a.5.5 0 00-.53 0l-2.8 1.75a.5.5 0 01-.75-.546l.797-3.204a.5.5 0 00-.164-.504L1.29 5.632a.5.5 0 01.287-.882L4.87 4.52a.5.5 0 00.428-.312l1.238-3.06z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}

.icon_documents {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.044 3.67h4.823c.626 0 1.135.51 1.135 1.136v7.574c0 .626-.51 1.136-1.135 1.136H3.044c-.626 0-1.135-.51-1.135-1.136V4.806c0-.626.51-1.135 1.135-1.135z' fill='%23#{$nav-icon-color}'/%3E%3Cpath d='M10.924.636H5.665c-.644 0-1.167.522-1.167 1.167v.063c0 .116.094.21.21.21h4.833c.626 0 1.135.51 1.135 1.136l.037 7.773c0 .116.095.21.21.21.645 0 1.168-.523 1.168-1.168V1.803c0-.645-.523-1.167-1.167-1.167z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}

.icon_reports {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.195 3.16h-2.92l-.3-.503a1.54 1.54 0 00-1.322-.75H4.65c-.848 0-1.54.686-1.54 1.534v1.623h-.803c-.574 0-1.034.463-1.034 1.037v4.912c0 .574.46 1.044 1.034 1.044h9.929a.49.49 0 00.492-.494V4.7c0-.847-.684-1.542-1.532-1.542zm.539 7.903h-.937c.002-.013-.002-.033-.002-.05V6.1c0-.575-.462-1.037-1.036-1.037H4.102V3.44c0-.298.248-.54.547-.54h2.004c.19 0 .368.101.465.265l.446.753a.5.5 0 00.428.247h3.203c.298 0 .538.237.538.536v6.36z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}

.icon_settings {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.536 6.389a.949.949 0 00-.441-.695.894.894 0 00-.796-.072c-.213.081-.45-.031-.547-.252a4.264 4.264 0 00-.417-.741.454.454 0 01.061-.612.936.936 0 00.332-.738.952.952 0 00-.369-.739 5.573 5.573 0 00-1.208-.714.911.911 0 00-.812.042.925.925 0 00-.46.663.439.439 0 01-.49.36 3.911 3.911 0 00-.829 0 .437.437 0 01-.49-.36.928.928 0 00-.458-.663.91.91 0 00-.812-.042c-.43.185-.836.425-1.208.714a.952.952 0 00-.37.738.94.94 0 00.334.74c.175.147.2.41.06.61-.163.232-.303.482-.416.742-.098.222-.338.332-.552.25a.888.888 0 00-.791.074.948.948 0 00-.442.694 5.698 5.698 0 000 1.438.95.95 0 00.442.695.89.89 0 00.796.072c.213-.08.45.031.546.252.114.26.254.51.417.741.14.2.114.464-.06.612a.936.936 0 00-.333.739c.006.291.14.56.369.738.372.289.779.529 1.208.714a.918.918 0 00.812-.042.925.925 0 00.46-.664.436.436 0 01.49-.359c.272.03.556.03.829 0a.436.436 0 01.49.36.928.928 0 00.458.663.91.91 0 00.813.042c.429-.185.835-.426 1.208-.715a.952.952 0 00.036-1.478c-.176-.147-.201-.41-.06-.61.162-.232.302-.482.416-.742.097-.22.335-.332.547-.252l.085-.233-.08.236c.264.1.55.073.791-.075a.948.948 0 00.441-.694 5.79 5.79 0 000-1.437zm-5.56 2.963c-1.21 0-2.194-1.007-2.194-2.244 0-1.238.984-2.245 2.194-2.245 1.209 0 2.193 1.007 2.193 2.245 0 1.237-.984 2.244-2.193 2.244z' fill='%23#{$nav-icon-color}'/%3E%3C/svg%3E");
}
