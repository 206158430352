@import '@scss/_module';

.progress_holder {
  overflow: hidden;
}

.progress_bar {
  display: block;
  max-width: rem(240);
  height: rem(3);
  border-radius: 4rem;
  background-color: $geyser;
  overflow: hidden;
  position: relative;

  div {
    height: 100%;
    background-color: $progress-bg-accent;
    border-radius: rem(6);
  }
}

.progress_label {
  display: block;
  font-weight: 500;
  margin-bottom: rem(3);
}
