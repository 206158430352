.react-tel-input {
  padding: 0;
  display: flex;
  align-items: center;

  > input[type=tel].form-control  {
    border: 0;
  }

  > div.flag-dropdown {
    background: transparent;
    border: 0;
  }
}