// If you want to override variables do it here
@import 'variables';

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import '~@coreui/coreui-pro/scss/coreui.scss';

// Import styles with dark layout
// If you want to use dark layout uncomment next line
// @import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// mixins
@import '~bootstrap/scss/mixins';
@import 'tools/mixins';

// base
@import 'base/fonts';
@import 'base/typography';
@import 'base/container';
@import 'base/buttons';
@import 'base/dl';
@import 'base/helpers';
@import 'base/icons';

// ie fixes
@import 'ie-fix';

// Temp fix for reactstrap
@import '~@coreui/coreui-pro/scss/_dropdown-menu-right.scss';

// Spinkit
$spinkit-spinner-color: $body-color;
@import '~spinkit/scss/spinkit.scss';

// auth pages
@import 'pages/auth';

// custom styles
@import 'components/select';
@import 'components/custom-circular-progressbar';
@import 'components/react-dates';
@import 'components/form';
@import 'components/top-panel';
@import 'components/panel';
@import 'components/card';
@import 'components/phone-input';
@import 'components/range';
