@import '@scss/_module';

.menu {
  position: relative;

  &:hover,
  &:focus {
    .menu_link::before {
      opacity: 0;
    }

    .menu_link::after {
      opacity: 1;
    }

    .menu_drop {
      opacity: 1;
      visibility: visible;
      z-index: 100;
    }
  }
}

.menu_link {
  z-index: 10;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: rem(20);
  height: rem(30);
  overflow: hidden;
  text-indent: -9999px;
  border: 0;
  padding: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(4);
    height: rem(16);
    margin: rem(-8) 0 0 rem(-2);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: opacity $anim-func-base $animspeed-base;
  }

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='4' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1.93' cy='1.562' r='1.5' transform='rotate(90 1.93 1.562)' fill='%23D5DFE6'/%3E%3Ccircle cx='1.93' cy='7.936' r='1.5' transform='rotate(90 1.93 7.936)' fill='%23D5DFE6'/%3E%3Ccircle cx='1.93' cy='14.31' r='1.5' transform='rotate(90 1.93 14.31)' fill='%23D5DFE6'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='4' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1.93' cy='1.562' r='1.5' transform='rotate(90 1.93 1.562)' fill='%23788F9E'/%3E%3Ccircle cx='1.93' cy='7.936' r='1.5' transform='rotate(90 1.93 7.936)' fill='%23788F9E'/%3E%3Ccircle cx='1.93' cy='14.31' r='1.5' transform='rotate(90 1.93 14.31)' fill='%23788F9E'/%3E%3C/svg%3E");
    opacity: 0;
  }
}

.menu_drop {
  position: absolute;
  top: 100%;
  right: 0;
  margin: rem(-4) 0 0;
  padding: rem(10) rem(8);
  background: white;
  box-shadow: $dropdown-box-shadow;
  transition: opacity $anim-func-base $animspeed-base;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  font-weight: 500;
  font-size: rem(12);
  line-height: rem(16);
  border-radius: rem(4);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-top: rem(4);
    white-space: nowrap;

    &:first-child {
      margin-top: 0;
    }
  }

  tbody tr:nth-child(6) &,
  tbody tr:nth-child(7) &,
  tbody tr:nth-child(8) &,
  tbody tr:nth-child(9) &,
  tbody tr:nth-child(10) &,
  tbody tr:nth-child(21) &,
  tbody tr:nth-child(22) &,
  tbody tr:nth-child(23) &,
  tbody tr:nth-child(24) &,
  tbody tr:nth-child(25) &,
  tbody tr:nth-child(26) &,
  tbody tr:nth-child(27) &,
  tbody tr:nth-child(28) &,
  tbody tr:nth-child(29) &,
  tbody tr:nth-child(30) &,
  tbody tr:nth-child(46) &,
  tbody tr:nth-child(47) &,
  tbody tr:nth-child(48) &,
  tbody tr:nth-child(49) &,
  tbody tr:nth-child(50) & {
    top: auto;
    bottom: 100%;
  }
}

.menu_sublink {
  color: inherit;
  width: 100%;
  display: block;
  padding: rem(7) rem(8) rem(9);
  transition: background-color $anim-func-base $animspeed-base;
  border-radius: rem(4);
  text-align: left;
  font-weight: 500;
  border: 0;

  &:hover,
  &:focus {
    background: $blacksqueeze;
    text-decoration: none;
  }
}

.link_delete {
  color: red !important;
}
