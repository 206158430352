@import '@scss/_module';

.data-grid-container .data-grid {
  th,
  td {
    &.cell {
      padding: 0.6em 1rem;
    }

    &:first-child {
      display: none !important;

      & + * {
        width: 13rem;
        text-align: left;
      }
    }
  }

  thead {
    th,
    td {
      color: $body-color !important;
      text-align: left !important;
      font-size: rem(14) !important;
      padding: rem(5) !important;
    }
  }
}

.data-grid-container .data-grid th.cell.read-only {
  background: $white;
}

.data-editor {
  height: rem(21) !important;
  margin: 0;
}
