@import '@scss/_module';

.nav-icon-drop {
  width: rem(20);
  height: rem(20);
  display: inline-block;
  vertical-align: middle;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-right: rem(4);
}
