@import '@scss/_module';

.filter {
  .dropdown-menu {
    min-width: rem(636);
    margin-top: rem(11);
    box-shadow: $dropdown-box-shadow;
    padding: rem(20) rem(30) rem(30);
    border: 1px solid $dropdown-border-color;
  }

  .input-group {
    display: block;
    margin-bottom: rem(16);
  }

  .form-check-input {
    position: static;
    float: left;
    vertical-align: top;
    margin: rem(3) rem(10) 0 0;
  }

  label {
    color: $codgray;
    display: block;
    overflow: hidden;
    margin: 0;
    // font-size: rem(13);
    line-height: rem(16);
  }
}

.filter-actions {
  .input-group {
    margin-bottom: 0;
  }
}
