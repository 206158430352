@import '@scss/_module';

.pie_holder {
  // transform: rotate(180deg);
  z-index: 1;
  position: relative;
  max-width: 214px;
  margin-left: auto;
  margin-right: auto;
  // box-shadow: 25px 19px 39.8846px rgba(186, 186, 186, 0.2);
  border-radius: 50%;
  background: $tooltip-bg-color;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    left: 26%;
    right: 26%;
    bottom: 0;
    height: 10%;
    background: transparent;
  }
}

// .icon_speedometer {
//   position: absolute;
//   left: 50%;
//   bottom: rem(-4);
//   width: rem(45);
//   height: rem(32);
//   margin-left: rem(-22.5);
//   background-image: url("data:image/svg+xml,%3Csvg width='45' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.8' d='M22.484 0c-3.874.004-7.68.977-11.052 2.823a22.198 22.198 0 00-8.195 7.715A21.298 21.298 0 00.01 21.156 21.238 21.238 0 002.584 31.94l9.57-4.873a11.033 11.033 0 01-1.336-5.61c.062-1.95.642-3.852 1.685-5.52a11.532 11.532 0 014.27-4.006 11.991 11.991 0 015.753-1.455c2.014.003 3.994.51 5.747 1.473a11.531 11.531 0 014.257 4.018 11.063 11.063 0 011.666 5.527 11.034 11.034 0 01-1.355 5.605L42.384 32a21.24 21.24 0 002.607-10.801 21.296 21.296 0 00-3.218-10.646 22.198 22.198 0 00-8.211-7.734A23.084 23.084 0 0022.484 0zM8.587 22.451h-6.78V21.14h6.78v1.312zm1.52-6.814l-5.874-3.285.677-1.138 5.875 3.286-.677 1.137zm4.843-5.166l-3.392-5.69 1.175-.656 3.392 5.69-1.175.656zm8.211-2.134h-1.355V1.776h1.355v6.561zm6.856 2.134l-1.175-.656 3.393-5.69 1.175.656-3.393 5.69zm4.16 4.029l5.876-3.286.677 1.138-5.875 3.285-.678-1.137zm2.199 7.951V21.14h6.785v1.312h-6.785zm-10.452-.656c0 .884-.363 1.731-1.008 2.356a3.498 3.498 0 01-2.432.976 3.498 3.498 0 01-2.433-.976 3.279 3.279 0 01-.983-2.76l-6.932-3.753 7.846 1.877a3.463 3.463 0 011.756-.972 3.54 3.54 0 012.016.154c.641.246 1.19.673 1.578 1.226.386.552.593 1.205.592 1.872z' fill='%232E353A'/%3E%3C/svg%3E");
//   background-repeat: no-repeat;
//   background-position: 50% 50%;
// }

.pie_value {
  z-index: 100;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: rem(36);
  border-radius: rem(18);
  background: $geyser;
  transform: translate(-50%, 0);

  font-weight: 600;
  font-size: rem(16);
  line-height: rem(36);
}

.pie_total {
  color: $regentgray;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 10%;
  right: 10%;
  transform: translateY(-60%);
  font-style: inherit;
  font-weight: 500;
  font-size: rem(10);
  line-height: 1.8;

  strong {
    color: $body-color;
    display: block;
    font-weight: 700;
    font-size: rem(32);
    line-height: 1;
  }

  em {
    color: $body-color;
    font-style: inherit;
    display: block;
    font-weight: 600;
    font-size: rem(14);
    line-height: rem(16);
    font-family: $font-alt;
    margin-top: rem(8);
  }
}

.pie_title {
  color: $regentgray;
  display: block;
  text-align: center;
  font-style: inherit;
  margin-top: rem(8);
  font-weight: 500;
  line-height: rem(18);

  strong {
    color: $body-color;
    font-weight: 700;
  }
}
