@import '@scss/_module';

.page_headline {
  margin-top: rem(-8);
}

.page_info {
  &,
  a {
    color: $regentgray;
  }

  a {
    transition: color $anim-func-base $animspeed-base;

    &:hover,
    &:focus {
      color: $blue;
      text-decoration: none;
    }
  }
}

.extra {
  &:empty {
    display: none;
  }

  &::before {
    color: $geyser;
    content: "|";
    margin: 0 rem(10);
  }
}
