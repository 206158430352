@import '@scss/_module';

.popup_holder {
  z-index: 1020;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($codgray, .5);
  border-radius: rem(8);
}

.popup {
  flex-basis: 438px;
}

.loader_holder {
  position: relative;
  width: rem(80);
  height: rem(80);
}
