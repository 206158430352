@import '@scss/_module';

.container-fluid {
  position: relative;
}

.table {
  font-weight: 500;
  font-size: rem(12);
  line-height: 1.3334;

  .panel tbody & {
    margin-bottom: rem(20);
  }

  thead {
    td,
    th {
      color: $table-head-color;
      border-bottom-width: $table-border-width !important;
      font-size: rem(12);
      font-weight: 500;

      &.desc,
      &.asc {
        color: $blue;
      }
    }

    tr:first-child {
      td, th {
        border-top: 0;
      }
    }
  }

  td {
    border-top: 0;
    vertical-align: middle;
  }

  tbody {
    th,
    td {
      padding-top: rem(8);
      padding-bottom: rem(8);
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    vertical-align: rem(-1);
  }
}

.custom-bordered {
  .react-bs-table {
    background-color: $white;
    border: 1px solid $table-border-color;
    border-radius: rem(4);
    overflow: hidden;

    tbody {
      tr:last-child {
        td, th {
          border-bottom: 0;
        }
      }
    }
  }
}

.custom-table {
  .react-bs-table-pagination {
    .dropdown-menu {
      top: auto;
      bottom: 100%;
    }

    .dropdown-toggle {
      font-weight: 500;
      font-size: rem(13);
    }
  }

  .react-bs-container-header {

  }

  th,
  td {
    border-bottom: $table-border-width solid $table-border-color;

    // &:first-child {
    //   border-left: $table-border-width solid $table-border-color;
    // }

    // &:last-child {
    //   border-right: $table-border-width solid $table-border-color;
    // }
  }

  .react-bs-table-tool-bar {
    // color: $toolbar-color;
    position: absolute;
    top: rem(11);
    left: 0;
    // width: 100%;
    // padding: rem(9) rem(15);
    // background: $toolbar-bg-color;

    div:empty {
      display: none;
    }

    .row > div {
      position: static;
    }

    // .react-bs-table-add-btn {
    //   color: $white;
    //   background: $blue;
    //   border-color: $blue;
    //   font-size: 0.875rem;
    //   padding: 0.375rem 1.25rem;
    //   font-weight: 500;
    // }

    .react-bs-table-search-form {
      position: absolute;
      top: 0;
      left: 0;
      width: rem(284);
      min-height: rem(38);
    }
  }
}

// .has-csv-cta {
//   .react-bs-table-tool-bar {
//     left: auto;
//     right: 15px;
//   }

//   .react-bs-table-csv-btn {
//     @include link;

//     position: absolute;
//     bottom: 100%;
//     right: 0;
//     overflow: hidden;
//     margin: 0;
//     padding: 0;
//     margin-right: rem(20);
//     margin-bottom: rem(56);

//     span {
//       width: rem(20);
//       height: rem(20);
//       overflow: hidden;
//       text-indent: -9999px;

//       &::before {
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237f8b96' width='24' height='24'%3E%3Cpath d='M11 12v6.586l-2.293-2.293a.999.999 0 10-1.414 1.414l4 4a1.006 1.006 0 001.414 0l4-4a.999.999 0 10-1.414-1.414L13 18.586V12a1 1 0 00-2 0zm10.455 6.908c1.355-.953 2.196-2.367 2.46-3.878s-.05-3.126-1.003-4.481a5.97 5.97 0 00-3.287-2.324A6.11 6.11 0 0018.004 8h-.523a8.978 8.978 0 00-3.907-4.758C11.593 2.07 9.162 1.653 6.755 2.27s-4.338 2.155-5.51 4.136-1.589 4.412-.972 6.819a8.895 8.895 0 001.983 3.734 1 1 0 101.486-1.338 6.901 6.901 0 01-1.533-2.893c-.481-1.873-.157-3.761.756-5.304S5.377 4.688 7.25 4.208s3.761-.157 5.304.756 2.736 2.412 3.216 4.285c.116.435.506.751.97.751h1.256c.37.001.737.052 1.09.151a3.973 3.973 0 012.19 1.548c.636.904.845 1.978.669 2.988s-.736 1.95-1.64 2.585a1 1 0 001.15 1.636z'/%3E%3C/svg%3E");
//       }

//       &::after {
//         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='24' height='24'%3E%3Cpath d='M11 12v6.586l-2.293-2.293a.999.999 0 10-1.414 1.414l4 4a1.006 1.006 0 001.414 0l4-4a.999.999 0 10-1.414-1.414L13 18.586V12a1 1 0 00-2 0zm10.455 6.908c1.355-.953 2.196-2.367 2.46-3.878s-.05-3.126-1.003-4.481a5.97 5.97 0 00-3.287-2.324A6.11 6.11 0 0018.004 8h-.523a8.978 8.978 0 00-3.907-4.758C11.593 2.07 9.162 1.653 6.755 2.27s-4.338 2.155-5.51 4.136-1.589 4.412-.972 6.819a8.895 8.895 0 001.983 3.734 1 1 0 101.486-1.338 6.901 6.901 0 01-1.533-2.893c-.481-1.873-.157-3.761.756-5.304S5.377 4.688 7.25 4.208s3.761-.157 5.304.756 2.736 2.412 3.216 4.285c.116.435.506.751.97.751h1.256c.37.001.737.052 1.09.151a3.973 3.973 0 012.19 1.548c.636.904.845 1.978.669 2.988s-.736 1.95-1.64 2.585a1 1 0 001.15 1.636z'/%3E%3C/svg%3E");
//       }
//     }

//     i {
//       display: none;
//     }
//   }
// }

.table-hover {
  tr {
    transition: all $anim-func-base $animspeed-base;
    cursor: default;

    &:hover {
      position: relative;
      background: $white;
      box-shadow: 0px 0px 25px rgba(56, 87, 104, 0.15);
    }
  }
}

// .table-responsive {
//   .react-bootstrap-table {
//     display: block;
//     width: 100%;
//     overflow-x: auto;
//     -webkit-overflow-scrolling: touch;

//     table {
//       table-layout: auto;

//       th,
//       td {
//         white-space: normal;
//       }
//     }
//   }
// }

.pt-53 {
  padding-top: rem(53);
}

.c-gray {
  color: $regentgray;

  &.btn {
    color: $regentgray !important;
  }
}

.icon-recurring {
  display: inline-block;
  vertical-align: top;
  width: rem(14);
  height: rem(15);
  // width: rem(44);
  // height: rem(40);
  // background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.7' fillRule='evenodd' clipRule='evenodd' d='M0 5.63v12.869c0 .398.162.78.451 1.061.289.282.68.44 1.089.44h19.249c.349-.075.66-.265.881-.539.221-.274.337-.614.329-.962V5.63H0zm14.63-3.11v-.643H7.7v1.501a1.367 1.367 0 01-.698.784c-.33.161-.712.188-1.062.074a1.381 1.381 0 01-.805-.68A1.326 1.326 0 015.06 2.52v-.643H1.54c-.418.027-.81.208-1.096.507A1.587 1.587 0 000 3.485v1.502h21.999V3.485a1.58 1.58 0 00-.318-1.009 1.651 1.651 0 00-.892-.6h-3.52v1.502a1.367 1.367 0 01-.698.784c-.33.161-.712.188-1.062.074a1.381 1.381 0 01-.804-.68 1.326 1.326 0 01-.076-1.036zM15.4.59v1.93a.648.648 0 00.243.281.673.673 0 00.723 0 .647.647 0 00.243-.28V.59a.582.582 0 00-.177-.417.613.613 0 00-.856 0A.583.583 0 0015.4.59zm-9.68 0v1.93a.648.648 0 00.243.281.672.672 0 00.723 0 .648.648 0 00.244-.28V.59a.648.648 0 00-.244-.281.672.672 0 00-.723 0 .648.648 0 00-.243.28zM7.15 16.46a5 5 0 01-1.405-2.622 4.924 4.924 0 01.347-2.94A5.079 5.079 0 018.07 8.656 5.27 5.27 0 0111 7.882a4.951 4.951 0 011.965.325c.625.24 1.192.604 1.664 1.07v-1.18h.88v2.68h-2.86V9.92h1.43a4.437 4.437 0 00-2.238-1.148 4.505 4.505 0 00-2.517.244 4.38 4.38 0 00-1.963 1.555 4.215 4.215 0 00-.761 2.351 4.11 4.11 0 00.739 2.331 4.27 4.27 0 001.947 1.533c.79.31 1.659.384 2.493.212a4.32 4.32 0 002.19-1.18c.408-.373.734-.82.96-1.318a3.99 3.99 0 00.36-1.578h.88a5.06 5.06 0 01-.93 2.827 5.259 5.259 0 01-2.378 1.855 5.407 5.407 0 01-3.032.267 5.322 5.322 0 01-2.68-1.41z' fill='%232E353A'/%3E%3C/svg%3E");
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.385 0c-.298 0-.539.256-.539.571V2.66C9.074-.19 4.651-.113 1.967 2.829.702 4.215-.003 6.07 0 7.999c0 .317.241.572.538.572.298 0 .539-.255.539-.571C1.077 4.528 3.73 1.715 7 1.716c1.662 0 3.246.74 4.368 2.041l-2.385.844c-.283.1-.436.424-.342.723.094.3.4.463.682.363l3.23-1.143a.57.57 0 00.37-.544V.57c0-.315-.242-.571-.54-.571zM13.462 6.429c-.298 0-.539.256-.539.571 0 3.472-2.653 6.285-5.924 6.285-1.661 0-3.246-.742-4.368-2.042l2.386-.844c.282-.1.435-.424.34-.723a.533.533 0 00-.68-.363l-3.232 1.143a.57.57 0 00-.368.544v3.429c0 .315.241.571.539.571.297 0 .538-.256.538-.571V12.34c2.772 2.848 7.195 2.773 9.88-.17 1.264-1.386 1.97-3.241 1.966-5.17 0-.316-.24-.572-.538-.572z' fill='%232E353A'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  // background-size: 50% auto;
  // background-position: 50% 50%;
  background-size: contain;
  margin-right: rem(4);
}

.icon-service-block {
  display: inline-block;
  vertical-align: top;
  width: rem(14);
  height: rem(15);
  // width: rem(50);
  // height: rem(36);
  // background-image: url("data:image/svg+xml,%3Csvg width='25' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.7' d='M12.491 0a12.7 12.7 0 00-6.14 1.588 12.397 12.397 0 00-4.553 4.34A12.088 12.088 0 00.005 11.9a12.067 12.067 0 001.43 6.066l5.318-2.74a6.269 6.269 0 01.193-6.262A6.44 6.44 0 019.32 6.711a6.597 6.597 0 013.195-.818c1.12.002 2.22.287 3.193.828a6.44 6.44 0 012.365 2.26 6.268 6.268 0 01.173 6.262L23.547 18a12.068 12.068 0 001.448-6.076 12.087 12.087 0 00-1.788-5.988 12.397 12.397 0 00-4.562-4.35A12.7 12.7 0 0012.491 0zm-7.72 12.629H1.003v-.738H4.77v.738zm.844-3.833L2.351 6.948l.377-.64 3.264 1.848-.377.64zm2.69-2.906l-1.884-3.2.653-.37 1.884 3.2-.652.37zm4.562-1.2h-.752V.999h.752v3.69zm3.81 1.2l-.654-.37 1.885-3.2.653.37-1.885 3.2zm2.31 2.266l3.265-1.848.376.64-3.264 1.848-.377-.64zm1.222 4.473v-.738h3.77v.738h-3.77zm-5.807-.37c0 .498-.201.974-.56 1.326a1.93 1.93 0 01-1.351.549 1.93 1.93 0 01-1.351-.55 1.855 1.855 0 01-.547-1.551L6.743 9.92l4.358 1.056c.262-.274.602-.464.976-.546a1.945 1.945 0 011.12.086c.356.138.661.379.876.69.215.31.33.677.329 1.053z' fill='%232E353A'/%3E%3C/svg%3E");
  // background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.372 11.606a.586.586 0 00.829 0l2.637-2.637a.586.586 0 10-.83-.829l-2.222 2.223-.795-.796a.586.586 0 10-.83.828l1.211 1.21z' fill='%232E353A'/%3E%3Cpath d='M14.414 2.11h-2.812V.585a.586.586 0 00-1.172 0v1.523H4.57V.586a.586.586 0 10-1.172 0v1.523H.586A.586.586 0 000 2.695v11.72c0 .323.262.585.586.585h13.828a.586.586 0 00.586-.586V2.695a.586.586 0 00-.586-.586zm-.586 11.718H1.172v-7.91h12.656v7.91zm0-9.082H1.172V3.281h12.656v1.465z' fill='%232E353A'/%3E%3C/svg%3E");
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.045 11.606c.087.11.205.171.328.171a.422.422 0 00.328-.171L7.79 8.969a.7.7 0 000-.829c-.182-.228-.475-.228-.656 0l-1.76 2.223-.63-.796c-.181-.23-.475-.229-.656 0a.7.7 0 000 .828l.958 1.21z' fill='%232E353A'/%3E%3Cpath d='M11.411 2.11H9.185V.585C9.185.262 8.977 0 8.72 0s-.464.262-.464.586v1.523H3.618V.586C3.618.262 3.41 0 3.154 0S2.69.262 2.69.586v1.523H.464c-.256 0-.464.263-.464.586v11.72c0 .323.208.585.464.585H11.41c.256 0 .464-.262.464-.586V2.695c0-.323-.208-.586-.464-.586zm-.464 11.718H.927v-7.91h10.02v7.91zm0-9.082H.927V3.281h10.02v1.465z' fill='%232E353A'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  // background-size: 50% auto;
  background-position: 0 50%;
  background-size: contain;
  margin-right: rem(4);
}

th.sort-column:focus {
  color: $blue !important;
}

.fa-sort,
.fa-sort-desc,
.fa-sort-asc {
  display: inline-block;
  vertical-align: middle;
  width: rem(7); // rem(10)
  height: rem(11); // rem(16)
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  margin: 0 0 0 rem(6) !important;
}

.fa-sort {
  // background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.371 10.5l3.5 4 3.5-4M1.371 5.5l3.5-4 3.5 4' stroke='%237F8B96' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.163 3.968c.208.2.537.2.744 0l1.766-1.764v6.885a.536.536 0 101.071 0V2.204l1.764 1.764a.536.536 0 00.758-.757L3.587.532A.536.536 0 002.83.531v.001L.148 3.211a.536.536 0 00.014.757z' fill='%23#{$icon-color-drop}'/%3E%3C/svg%3E");
}

// .fa-sort-asc {
//   background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.371 1.5l3.5 4 3.5-4' stroke='%237F8B96' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
//   background-position: 0 100%;
// }

// .fa-sort-desc {
//   background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.371 5.5l3.5-4 3.5 4' stroke='%237F8B96' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
// }

.fa-sort-desc {
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.163 3.968c.208.2.537.2.744 0l1.766-1.764v6.885a.536.536 0 101.071 0V2.204l1.764 1.764a.536.536 0 00.758-.757L3.587.532A.536.536 0 002.83.531v.001L.148 3.211a.536.536 0 00.014.757z' fill='%23#{$icon-color-blue}'/%3E%3C/svg%3E");
}

.fa-sort-asc {
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.253 6.534a.536.536 0 00-.744 0L3.743 8.298V1.413a.536.536 0 10-1.071 0v6.885L.908 6.534a.536.536 0 00-.758.757L2.83 9.97c.209.21.548.21.758 0l2.68-2.679a.536.536 0 00-.014-.757z' fill='%23#{$icon-color-blue}'/%3E%3C/svg%3E");
}

// tooltip
// i[data-title] {
//   position: relative;
//   cursor: default;

//   &::after {
//     color: $body-color;
//     content: attr(data-title);
//     padding: 0 rem(10);
//     position: absolute;
//     left: 100%;
//     top: 50%;
//     z-index: 10;
//     background: $tooltip-bg-color;
//     transform: translate(0, -50%);
//     transition: opacity $anim-func-base $animspeed-base;
//     font-style: normal;
//     font-size: rem(12);
//     box-shadow: 0px 3.46667px 17.3333px rgba(0, 0, 0, 0.15);
//     white-space: nowrap;
//     margin-left: rem(8);
//     opacity: 0;
//   }

//   &:hover {
//     &::after {
//       opacity: 1;
//     }
//   }
// }

.table-area {
  position: relative;

  // .react-bs-container-body {
  //   overflow-x: auto;
  //   overflow-y: hidden;
  // }

  // fix row hover
  // .react-bs-container-header,
  // .react-bs-container-body {
  //   width: auto;
  //   margin-left: -#{$container-gap};
  //   margin-right: -#{$container-gap};
  //   padding-left: #{$container-gap};
  //   padding-right: #{$container-gap};
  // }
}
