@import '@scss/_module';

.projects_table {
  color: $body-color;
  font-weight: 500;
  line-height: 1;
  // font-size: rem(16);
  // line-height: rem(24);

  th {
    color: $table-head-color;
  }

  td {
    vertical-align: middle;
  }
}

.project_name {
  font-weight: 600;
  font-size: rem(13);
  line-height: rem(16);
  margin-bottom: rem(2);
  display: block;
}

.project_title {
  color: $regentgray;
  display: block;
  font-weight: 500;
  font-style: inherit;
  // font-size: rem(14);
  // line-height: 1.5;
}

.project_status {
  font-size: rem(18);
}

.link_color_inherit {
  color: inherit;
  transition: color $anim-func-base $animspeed-base;

  &:hover,
  &:focus {
    color: $blue;
    text-decoration: none;
  }
}

.percentage {
  min-width: rem(45);
  color: $regentgray;
  display: inline-block;
  vertical-align: 0;
  background: $aquahaze;

  border: 1px solid $geyser;
  border-radius: rem(4);
  padding: rem(3) rem(4) rem(4);
  text-align: center;
}

.toolbar {
  min-height: rem(60);
  padding: rem(11) $container-gap;
  background: $white;
  border-bottom: 1px solid $geyser;
  margin-bottom: rem(14);
  padding-left: rem(28);
}

.search_field {
  width: rem(284);
  margin-right: rem(10);
}
