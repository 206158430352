@import '@scss/_module';

.user_link {
    color: $white;
    position: relative;
    white-space: nowrap;
    padding: rem(15) rem(6) rem(15) rem(12);
    letter-spacing: 0;
  
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: rem(12);
      height: rem(8);
      background-image: $little-arrow;
      background-repeat: no-repeat;
      margin-left: rem(10);
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.457 4.382l-3.456 3.46a.54.54 0 11-.764-.765L9.77 4.541H.541a.54.54 0 010-1.082h9.23L7.237.923a.54.54 0 01.764-.765l3.456 3.46a.54.54 0 010 .764z' fill='%23#{$icon-color-sidebar}' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
  }
  
  .user_avatar {
    height: rem(30);
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
    margin-right: rem(12);
  }
  
  .user_info {
    position: relative;
    text-align: left;
    padding: rem(12) rem(16);
    border-bottom: 1px solid $iron;
  
    // @media screen and (max-width: 570px) {
    //   display: none;
    // }
  }
  
  .user_name {
    display: block;
    font-weight: 500;
    font-size: rem(18);
    line-height: rem(24);
    letter-spacing: rem(0.214286);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .user_title {
    color: $dovegray;
    display: block;
    // font-size: rem(13);
    // line-height: rem(18);
    font-style: inherit;
  }
  
  .dropdown_items {
    padding: rem(6) rem(8) rem(9);
  }
  
  .icon_settings {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.909 9.127a1.356 1.356 0 00-.63-.992 1.277 1.277 0 00-1.138-.104c-.303.117-.643-.044-.781-.36a6.083 6.083 0 00-.595-1.058c-.201-.287-.163-.663.087-.874.31-.26.482-.645.474-1.055a1.359 1.359 0 00-.527-1.055 7.962 7.962 0 00-1.726-1.02 1.301 1.301 0 00-1.16.06c-.35.197-.59.543-.656.947-.053.329-.353.547-.7.513a5.588 5.588 0 00-1.185 0c-.347.034-.645-.184-.699-.513a1.326 1.326 0 00-.656-.948 1.3 1.3 0 00-1.16-.06 7.911 7.911 0 00-1.726 1.02 1.36 1.36 0 00-.527 1.055c-.007.411.166.796.476 1.058.25.21.287.585.085.872-.232.33-.432.687-.593 1.059-.14.316-.484.474-.79.357a1.268 1.268 0 00-1.13.106 1.355 1.355 0 00-.63.991 8.141 8.141 0 000 2.055c.053.415.282.776.63.991a1.27 1.27 0 001.138.104c.305-.115.644.045.78.36.163.372.364.728.595 1.059.202.286.164.662-.086.873-.31.26-.482.645-.475 1.056.009.416.2.801.527 1.054a7.96 7.96 0 001.727 1.02 1.299 1.299 0 001.16-.06c.35-.197.59-.543.656-.948.053-.329.352-.55.7-.513.389.042.795.042 1.185 0a.623.623 0 01.699.513c.066.404.305.75.656.948.357.202.78.222 1.16.06a7.954 7.954 0 001.726-1.02 1.36 1.36 0 00.527-1.055 1.34 1.34 0 00-.476-1.058c-.25-.21-.287-.585-.086-.872.233-.33.433-.687.595-1.058.139-.316.479-.475.782-.36l.122-.334-.115.337c.377.145.787.105 1.13-.106.348-.215.578-.577.63-.992a8.258 8.258 0 000-2.053zM9.965 13.36c-1.727 0-3.133-1.438-3.133-3.206s1.406-3.207 3.133-3.207c1.728 0 3.134 1.439 3.134 3.207 0 1.768-1.406 3.206-3.134 3.206z' fill='%23#{$icon-color-drop}'/%3E%3C/svg%3E");
  }

  .icon_switchworkspace {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 0L3 4.433h3v7.79h2v-7.79h3L7 0zM14 15.566V7.777h-2v7.79H9.002l4 4.433L17 15.566h-3z' fill='%23#{$icon-color-drop}'/%3E%3C/svg%3E");
  }

  .icon_myprofile {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.789 13.267l-3.102-2.097 1.193-2.007c.22-.364.35-.808.35-1.285V4.852c0-1.909-1.446-3.456-3.23-3.456S6.77 2.943 6.77 4.852v3.026c0 .477.13.921.355 1.294l-.005-.01 1.193 2.007-3.102 2.098a2.167 2.167 0 00-.925 1.798v2.304l11.428-.019v-2.285c0-.753-.366-1.415-.918-1.793l-.007-.005z' fill='%23#{$icon-color-drop}'/%3E%3C/svg%3E");
  }

  .icon_referrals {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.989 8.393l-1.087 6.926c-.062.393-.374.681-.739.681H4.837c-.365 0-.677-.288-.739-.681L3.011 8.393a.859.859 0 01.289-.795.696.696 0 01.779-.081l2.894 1.54 2.372-4.666c.264-.521 1.047-.521 1.311 0l2.372 4.667 2.894-1.541a.696.696 0 01.779.08.86.86 0 01.288.796z' fill='%23#{$icon-color-drop}'/%3E%3C/svg%3E");
  }

  .icon_switchmode {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.75 11.21a.647.647 0 00-.733-.047 5.231 5.231 0 01-6.39-.794 5.22 5.22 0 01-.794-6.378.657.657 0 00-.043-.737.649.649 0 00-.7-.227 7.147 7.147 0 00-2.999 1.791A7.091 7.091 0 003 9.866c0 1.908.743 3.7 2.091 5.049a7.111 7.111 0 005.921 2.031c.408-.11.821-.199 1.238-.265a7.09 7.09 0 002.93-1.767 7.148 7.148 0 001.793-3 .656.656 0 00-.223-.704z' fill='%23#{$icon-color-drop}'/%3E%3C/svg%3E");
  }

  .icon_logout {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 10.5C2 12.98 4.094 15 6.667 15h6.666C15.906 15 18 12.981 18 10.5S15.906 6 13.333 6H6.667C4.094 6 2 8.019 2 10.5zm8.667 0c0-1.418 1.196-2.571 2.666-2.571 1.471 0 2.667 1.153 2.667 2.57 0 1.42-1.196 2.572-2.667 2.572-1.47 0-2.666-1.153-2.666-2.571z' fill='%23#{$icon-color-drop}'/%3E%3C/svg%3E");
  }