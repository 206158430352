@import '@scss/_module';

.headline {
  padding: rem(26) $container-gap rem(16); // bottom 24px
  border-bottom: 1px solid $toolbar-border-color;
  background: $white;
}

.info {
  color: $regentgray;
  display: block;
  font-weight: 500;
  // font-size: rem(13);
  // line-height: rem(18);
  font-style: inherit;
  font-family: $font-alt;
  margin-top: rem(4);
}

.headline__title {
  flex-basis: 50%;
  margin-top: rem(-8);
}

.headline__action {
  flex-basis: 50%;
  white-space: nowrap;
}
