@import '@scss/_module';

.view_as {
  color: $regentgray;
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  // font-size: rem(13);
  line-height: 1;
}

.view_label {
  display: inline-block;
  vertical-align: middle;
  padding-right: rem(12);
  padding-bottom: rem(2);
}

.view_links {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid $geyser;
  border-radius: rem(4);
}

.link_view {
  color: $codgray;
  min-width: rem(82);
  text-align: center;
  border-left: 1px solid $geyser;
  padding: rem(12) rem(6) rem(11);
  opacity: .5;
  border-radius: 0 rem(4) rem(4) 0;
  transition: opacity $anim-func-base $animspeed-base, background-color $anim-func-base $animspeed-base;

  &:first-child {
    border-left: 0;
    border-radius: rem(4) 0 0 rem(4);
  }

  &:hover,
  &:focus,
  &.active {
    color: $codgray;
    background: $aquahaze;
    text-decoration: none;
    opacity: 1;
  }
}

.icon_dollar {
  display: inline-block;
  vertical-align: top;
  width: rem(7);
  height: rem(14);
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.495 0a.49.49 0 00-.354.156A.513.513 0 003 .522v.885c-.745.081-1.42.342-1.948.778A2.907 2.907 0 000 4.444c0 1.008.53 1.723 1.187 2.131.56.349 1.2.55 1.813.778v4.22c-1.018-.137-1.794-.694-2.02-1.48a.51.51 0 00-.243-.304.49.49 0 00-.38-.04.503.503 0 00-.297.247.522.522 0 00-.04.39c.375 1.297 1.598 2.066 2.98 2.216v.88a.522.522 0 00.144.366A.5.5 0 003.5 14a.491.491 0 00.356-.152A.513.513 0 004 13.48v-.879c.745-.083 1.42-.353 1.948-.789A2.896 2.896 0 007 9.56c0-1.007-.53-1.722-1.188-2.131-.56-.348-1.2-.554-1.812-.783v-4.21c1.018.136 1.794.684 2.02 1.47a.516.516 0 00.242.305.492.492 0 00.678-.208.52.52 0 00.039-.39c-.374-1.297-1.598-2.057-2.98-2.205V.522a.522.522 0 00-.144-.37.5.5 0 00-.36-.152zM3 2.435v3.826c-.502-.186-.962-.354-1.292-.56C1.233 5.407 1 5.139 1 4.445c0-.627.248-1.106.682-1.465.333-.276.788-.468 1.318-.544zm1 5.307c.502.188.962.355 1.292.56.475.296.708.563.708 1.257 0 .628-.249 1.101-.682 1.46-.333.276-.788.477-1.318.555V7.742z' fill='%23#{$icon-color-base}'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-right: rem(4);
}

.icon_clock {
  display: inline-block;
  vertical-align: top;
  width: rem(12);
  height: rem(15);
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.988 12.075h-.594v-.155c0-2.477-1.128-3.29-1.97-3.87-.498-.348-.747-.542-.747-.91 0-.367.25-.541.747-.909.822-.58 1.97-1.393 1.97-3.87v-.155h.594c.612 0 1.09-.503 1.09-1.103S10.581 0 9.988 0H1.09C.478 0 0 .503 0 1.103s.497 1.103 1.09 1.103h.594v.174c0 2.477 1.129 3.29 1.97 3.87.498.349.747.542.747.91 0 .367-.25.542-.747.91-.822.58-1.97 1.393-1.97 3.87v.154H1.09c-.613 0-1.091.503-1.091 1.103 0 .62.497 1.103 1.09 1.103h8.898c.612 0 1.09-.503 1.09-1.103 0-.619-.497-1.122-1.09-1.122zm.363-10.972a.365.365 0 01-.363.368H1.09a.365.365 0 01-.364-.368C.727.89.9.735 1.091.735h8.897c.19 0 .363.174.363.368zM5.128 7.14c0-.754-.536-1.141-1.053-1.509-.784-.542-1.664-1.18-1.664-3.27v-.155h6.237v.174c0 2.09-.88 2.729-1.664 3.27-.517.368-1.053.755-1.053 1.51 0 .754.536 1.141 1.053 1.51.784.541 1.664 1.18 1.664 3.27v.154H2.411v-.155c0-2.09.88-2.728 1.664-3.27.517-.387 1.053-.755 1.053-1.529zm5.223 6.057a.365.365 0 01-.363.368H1.09a.365.365 0 01-.364-.368c0-.213.172-.367.364-.367h8.897c.19 0 .363.154.363.367z' fill='%23ABAFB3'/%3E%3Cpath d='M7.316 9.713c-1.319-.804-2.92-.335-3.607 0-1.385.958-.704 2.052-.704 2.052h5.151c.27-.349.479-1.248-.84-2.052z' fill='%23#{$icon-color-base}'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-right: rem(4);
}