@import '@scss/_module';

.copy {
  font-weight: 500;
  // font-size: rem(13);
  margin-right: rem(35);

  a {
    color: $black;

    &:hover,
    &:focus {
      color: $regentgray;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    display: block;
    padding-top: rem(14);
    padding-bottom: rem(8);
  }
}

.footer_nav {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    float: left;
    margin-left: rem(35);
    font-weight: 500;
    // font-size: rem(13);

    &:first-child {
      margin-left: 0;
    }
  }

  a {
    color: $regentgray;
    transition: color $anim-func-base $animspeed-base;
    padding: 0;

    &:hover,
    &:focus {
      color: $black;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 560px) {
    display: block;
    padding-bottom: rem(14);
  }
}
