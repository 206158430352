@import '@scss/_module';

.sub-menu {
    @include list-reset;
    display: inline-block;
    vertical-align: top;
    padding-top: rem(3);
    // margin-right: rem(-10);
    // display: flex;
    // justify-content: space-between;
  
    li {
      display: inline-block;
      vertical-align: top;
      margin-left: rem(5);
      margin-right: rem(5);
      font-weight: 500;
      // font-size: rem(13);
      line-height: rem(19);
    }
  
    a {
      color: $regentgray !important;
      display: block;
      font-weight: 500;
      padding: rem(6) rem(14) rem(8);
      border-radius: rem(4);
      transition: color $anim-func-base $animspeed-base, background-color $anim-func-base $animspeed-base;
      cursor: pointer;
  
      &.active,
      &:hover {
        color: $codgray !important;
        background-color: $aquahaze;
        text-decoration: none;
      }
    }
  }