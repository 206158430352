@import '@scss/_module';

.page-title {
  color: $body-color;
  font-weight: bold;
  font-size: rem(24);
  line-height: 1.25;
  margin: rem(2) 0 rem(4);
}

.app-body {
  margin-top: 0 !important; // no header
}

// drop
// .nav-dropdown-items {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: $sidebar-minimized-width;
//   width: #{$sidebar-width - $sidebar-minimized-width};
//   background: $outerspace;
//   transition: opacity $anim-func-base $animspeed-base;
//   // opacity: 0;

//   .sidebar & {
//     max-height: none;

//     .nav-icon {
//       display: none;
//     }
//   }

//   .sidebar:hover & {
//     opacity: 0;
//   }
// }

// .navbar-toggler {
//   @media screen and (min-width: 992px) {
//     display: none;
//   }
// }

.navbar-toggler {
  z-index: 10;
  // position: absolute;
  top: rem(20);
  left: 100%;
  margin-left: rem(20);
  background: rgba(0, 0, 0, .5);
}

.toggler-holder {
  background: $white;
  padding-top: rem(10);
  padding-bottom: rem(10);
}
