@import '@scss/_module';

.input-group-prepend {
  margin-right: 0;

  .option-btn-left.show-drop & {
    border-bottom-left-radius: 0;
    border: 1px solid $input-border-color;
    border-right: 0;
  }

  .form-control:focus + & {
    border-color: $input-focus-border-color;
  }

  .form-control.is-invalid + &,
  .was-validated .form-control:invalid:focus + & {
    border-color: $flamingo;
  }

  // .form-control.is-valid + &,
  // .form-control.is-valid:focus + & {
  //   border-color: $green;
  // }

  .ico-flag {
    display: inline-block;
    vertical-align: -0.1875rem;
    font-size: 1.25rem;
    line-height: 1;
    margin-right: 4px;

    &:empty {
      width: 18px;
      height: 14px;
      vertical-align: -0.125rem;
      overflow: hidden;
      background-color: $flag-bg-color;
      margin-right: 9px;
    }
  }

  .country-code {
    font-weight: 600;
  }
}

.option-btn-left {
  > .form-control {
    border-left-width: 0;
    border-radius: 0 $border-radius $border-radius 0 !important;
    padding-left: 0.5rem;
  }

  &.show-drop {
    .form-control.is-invalid,
    .form-control.is-invalid + .input-group-prepend,
    .was-validated .form-control:invalid:focus + .input-group-prepend {
      border-color: $input-border-color;
      background-image: none;
    }
  }

  &.show-drop > .form-control {
    border-bottom-right-radius: 0 !important;
  }

  .input-group-prepend {
    margin-right: 0;
    border-style: solid;
    border-color: $input-border-color;
    border-width: 1px 0 1px 1px;
    transition: border-color $animspeed-base ease-in-out;
    border-radius: $border-radius 0 0 $border-radius;

    .dropdown-toggle {
      border-radius: $border-radius 0 0 $border-radius !important;
    }

    .is-valid + & {
      color: $white;
      border-color: $blue !important;
    }
  }

  .dropdown-toggle {
    border: 0;
    padding-right: 0.5rem;
  }
}

.input-group-dropdown {
  position: relative;

  .dropdown-toggle {
    color: $codgray;
    box-shadow: none !important;
    background: transparent !important;
    outline: none !important;
    padding-top: rem(6);
    padding-bottom: rem(8);
    padding-left: rem(12);

    &.flag-inactive {
      color: rgba(255, 255, 255, 0.5);
    }

    &:focus,
    &:active {
      // color: $white;
    }
  }

  .dropdown {
    &.show .btn.btn-secondary {
      background-color: $secondary;
      border-color: $secondary;
    }
  }
  
  .dropdown-menu {
    max-height: rem(356);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: rem(5) rem(4) rem(9);
    margin-top: 0;
    margin-left: -5px;
    border-color: $secondary;
  
    left: -1px !important;
    right: 1px !important;
    border: 1px solid $input-border-color;
    border-radius: 0 0 $border-radius $border-radius;
    margin: 0;
  }
  
  .dropdown-item {
    margin-top: rem(4);
    background-color: $dropdown-bg;
    border-radius: rem(2) !important;
    padding: rem(5) rem(6);
    border: 0;
    border-bottom: 0;
  
    &:focus,
    &:hover {
      // color: $white;
      // outline: 1px dotted #fff;
      // outline-offset: -1px;
      background-color: $blackhaze;
      // background: $gray;
      outline: none;
    }
  
    > div {
      width: calc(100% - 50px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .dropdown-toggle {
    font-weight: 500;
  
    &::after {
      color: $codgray;
      border-left-width: 0.2857em;
      border-right-width: 0.2857em;
      border-top-width: 0.357em;
      vertical-align: 0.07145em;
      margin-left: 0.5715em;
    }
  }
  
  .form-control:focus + .input-group-prepend .dropdown-toggle {
    background: $input-bg !important;
  }
}

.input-group-dropdown .dropdown-toggle.flag-inactive {
  color: $input-placeholder-color;
}
