@import '@scss/_module';

.navigation {
  padding: rem(10) #{$container-gap} rem(5);
  background: $white;
  border-bottom: 1px solid $geyser;
}

.btns_set_left {
  > * {
    margin-bottom: rem(5);
  }
}

.btns_set_right {
  > * {
    margin-left: 0.25rem !important;
    margin-bottom: rem(5);
  }
}

.navigation_title {
  font-weight: 600;
  font-size: rem(18);

  span {
    font-weight: normal;
  }
}

.button {
  display: inline-block;
  vertical-align: middle;
  width: rem(28);
  height: rem(40);
  overflow: hidden;
  text-indent: -9999px;
  padding: 0;
  border-radius: 0;
  border: 0;
  transition: $btn-transition;
  background: white;
  // $codgray

  &::after {
    opacity: 0;
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none !important;
    background: transparent !important;
    // border-color: $blue;

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }
}

.link_download {
  @include link;
}

.icon_download {
  width: rem(20);
  height: rem(20);

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237f8b96' width='24' height='24'%3E%3Cpath d='M11 12v6.586l-2.293-2.293a.999.999 0 10-1.414 1.414l4 4a1.006 1.006 0 001.414 0l4-4a.999.999 0 10-1.414-1.414L13 18.586V12a1 1 0 00-2 0zm10.455 6.908c1.355-.953 2.196-2.367 2.46-3.878s-.05-3.126-1.003-4.481a5.97 5.97 0 00-3.287-2.324A6.11 6.11 0 0018.004 8h-.523a8.978 8.978 0 00-3.907-4.758C11.593 2.07 9.162 1.653 6.755 2.27s-4.338 2.155-5.51 4.136-1.589 4.412-.972 6.819a8.895 8.895 0 001.983 3.734 1 1 0 101.486-1.338 6.901 6.901 0 01-1.533-2.893c-.481-1.873-.157-3.761.756-5.304S5.377 4.688 7.25 4.208s3.761-.157 5.304.756 2.736 2.412 3.216 4.285c.116.435.506.751.97.751h1.256c.37.001.737.052 1.09.151a3.973 3.973 0 012.19 1.548c.636.904.845 1.978.669 2.988s-.736 1.95-1.64 2.585a1 1 0 001.15 1.636z'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='24' height='24'%3E%3Cpath d='M11 12v6.586l-2.293-2.293a.999.999 0 10-1.414 1.414l4 4a1.006 1.006 0 001.414 0l4-4a.999.999 0 10-1.414-1.414L13 18.586V12a1 1 0 00-2 0zm10.455 6.908c1.355-.953 2.196-2.367 2.46-3.878s-.05-3.126-1.003-4.481a5.97 5.97 0 00-3.287-2.324A6.11 6.11 0 0018.004 8h-.523a8.978 8.978 0 00-3.907-4.758C11.593 2.07 9.162 1.653 6.755 2.27s-4.338 2.155-5.51 4.136-1.589 4.412-.972 6.819a8.895 8.895 0 001.983 3.734 1 1 0 101.486-1.338 6.901 6.901 0 01-1.533-2.893c-.481-1.873-.157-3.761.756-5.304S5.377 4.688 7.25 4.208s3.761-.157 5.304.756 2.736 2.412 3.216 4.285c.116.435.506.751.97.751h1.256c.37.001.737.052 1.09.151a3.973 3.973 0 012.19 1.548c.636.904.845 1.978.669 2.988s-.736 1.95-1.64 2.585a1 1 0 001.15 1.636z'/%3E%3C/svg%3E");
  }
}

.link_edit {
  @include link;

  i {
    width: rem(15);
    height: rem(19);

    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.537 3.288L10.171.943a.742.742 0 00-1.069 0l-7.1 7.036c-.076.075-.076.151-.153.227v.075L.63 11.837c-.077.303 0 .605.152.757a.696.696 0 00.534.226c.076 0 .153 0 .229-.075l3.588-1.21h.076c.076 0 .153-.075.229-.151l7.1-7.036a.728.728 0 000-1.06z' fill='%237F8B96'/%3E%3Cpath d='M10.934 3.817l-.381.378L9.255 2.91l.381-.378 1.298 1.286zM4.902 9.795L3.605 8.509l4.581-4.54 1.298 1.287-4.582 4.539zM2.536 10.852l.305-.907.611.605-.916.302z' fill='%23fff'/%3E%3C/svg%3E");
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.537 3.288L10.171.943a.742.742 0 00-1.069 0l-7.1 7.036c-.076.075-.076.151-.153.227v.075L.63 11.837c-.077.303 0 .605.152.757a.696.696 0 00.534.226c.076 0 .153 0 .229-.075l3.588-1.21h.076c.076 0 .153-.075.229-.151l7.1-7.036a.728.728 0 000-1.06z' fill='%23FFFFFF'/%3E%3Cpath d='M10.934 3.817l-.381.378L9.255 2.91l.381-.378 1.298 1.286zM4.902 9.795L3.605 8.509l4.581-4.54 1.298 1.287-4.582 4.539zM2.536 10.852l.305-.907.611.605-.916.302z' fill='%23009bff'/%3E%3C/svg%3E");
      opacity: 0;
    }
  }
}

.btn_today {
  min-width: rem(65);
  padding-left: rem(10);
  padding-right: rem(10);
  margin-right: rem(14);
}

.date_picker {
  > * {
    display: inline-block;
    vertical-align: top;
  }
}

.main_label {
  color: $codgray;
  font-style: normal;
  font-weight: 600;
  font-size: rem(18);
  line-height: rem(30);
  letter-spacing: rem(0.214286);
  margin-right: rem(21);
}
