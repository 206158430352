@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin link {
  display: inline-block;
  vertical-align: middle;
  width: rem(40);
  height: rem(40);
  border: 1px solid $geyser; // $mischka
  border-radius: rem(3);
  margin-left: rem(6);
  text-align: center;
  padding: rem(7) rem(5) rem(10);
  line-height: rem(20);
  transition: background-color $animspeed-base $anim-func-base,
    border-color $animspeed-base $anim-func-base, opacity $animspeed-base $anim-func-base;
  background: white;

  i,
  span {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      transition: opacity $animspeed-base $anim-func-base;
    }

    &::after {
      opacity: 0;
    }
  }

  &:hover,
  &:focus,
  &.active {
    background-color: $blacksqueeze;

    i,
    span {
      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}
