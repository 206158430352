.icon-add {
  $size: 19px;
  $icon-size: 9px;

  position: relative;
  width: $size;
  height: $size;
  background-color: $lightblue;
  border-radius: 50%;
  margin-right: rem(6);

  &::before,
  &::after {
    content: "";
    position: absolute;
    overflow: hidden;
    background: $blue;
  }

  &::before {
    top: calc((#{$size} - 1px) / 2);
    left: calc((#{$size} - #{$icon-size}) / 2);
    width: $icon-size;
    height: 1px;
  }

  &::after {
    top: calc((#{$size} - #{$icon-size}) / 2);
    left: calc((#{$size} - 1px) / 2);
    height: $icon-size;
    width: 1px;
  }
}

.icon-remove {
  position: relative;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: rem(5);

  &::before {
    content: "";
    display: block;
    width: rem(16);
    height: rem(16);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23fff'/%3E%3Ccircle cx='8' cy='8' r='7.5' stroke='%23009BFF' stroke-opacity='.25'/%3E%3Cpath d='M5 5l6 6M11 5l-6 6' stroke='%23009BFF' stroke-linecap='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
