@import '@scss/_module';

// .project_menu {
//   > * {
//     margin-left: rem(8);
//     vertical-align: top;
//   }
// }

.project_updated {
  color: $silversand;
  font-size: rem(13);
  line-height: rem(35);
  text-transform: uppercase;
  padding-top: rem(2);

  strong {
    color: $regentgray;
    font-weight: inherit;
  }

  @media screen and (max-width: 570px) {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.link_update {
  @include link;
  opacity: 1;
}

.icon_update {
  width: rem(14);
  height: rem(15);

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.385 0c-.298 0-.539.256-.539.571V2.66C9.074-.19 4.651-.113 1.967 2.829.702 4.215-.003 6.07 0 7.999c0 .317.241.572.538.572.298 0 .539-.255.539-.571C1.077 4.528 3.73 1.715 7 1.716c1.662 0 3.246.74 4.368 2.041l-2.385.844c-.283.1-.436.424-.342.723.094.3.4.463.682.363l3.23-1.143a.57.57 0 00.37-.544V.57c0-.315-.242-.571-.54-.571zM13.463 6.429c-.298 0-.539.256-.539.571 0 3.472-2.653 6.285-5.924 6.285-1.661 0-3.246-.742-4.368-2.042l2.386-.844c.282-.1.435-.424.34-.723a.533.533 0 00-.68-.363l-3.232 1.143a.57.57 0 00-.368.544v3.429c0 .315.241.571.539.571.297 0 .538-.256.538-.571V12.34c2.772 2.848 7.195 2.773 9.88-.17 1.264-1.386 1.97-3.241 1.966-5.17 0-.316-.24-.572-.538-.572z' fill='%23#{$link-icon-color}'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.385 0c-.298 0-.539.256-.539.571V2.66C9.074-.19 4.651-.113 1.967 2.829.702 4.215-.003 6.07 0 7.999c0 .317.241.572.538.572.298 0 .539-.255.539-.571C1.077 4.528 3.73 1.715 7 1.716c1.662 0 3.246.74 4.368 2.041l-2.385.844c-.283.1-.436.424-.342.723.094.3.4.463.682.363l3.23-1.143a.57.57 0 00.37-.544V.57c0-.315-.242-.571-.54-.571zM13.463 6.429c-.298 0-.539.256-.539.571 0 3.472-2.653 6.285-5.924 6.285-1.661 0-3.246-.742-4.368-2.042l2.386-.844c.282-.1.435-.424.34-.723a.533.533 0 00-.68-.363l-3.232 1.143a.57.57 0 00-.368.544v3.429c0 .315.241.571.539.571.297 0 .538-.256.538-.571V12.34c2.772 2.848 7.195 2.773 9.88-.17 1.264-1.386 1.97-3.241 1.966-5.17 0-.316-.24-.572-.538-.572z' fill='%23#{$link-icon-color-hover}'/%3E%3C/svg%3E");
  }
}

.link_edit {
  @include link;
  opacity: 1;
}

.icon_edit {
  width: rem(13);
  height: rem(13);

  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.338.663a2.262 2.262 0 00-3.2 0L1.355 8.446a.597.597 0 00-.143.23L.03 12.223a.59.59 0 00.747.748l3.546-1.182a.592.592 0 00.23-.143l7.784-7.783a2.262 2.262 0 000-3.2zm-.836 2.364L3.82 10.709l-2.295.766.764-2.292L9.974 1.5a1.08 1.08 0 011.528 1.526z' fill='%23#{$link-icon-color}'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.338.663a2.262 2.262 0 00-3.2 0L1.355 8.446a.597.597 0 00-.143.23L.03 12.223a.59.59 0 00.747.748l3.546-1.182a.592.592 0 00.23-.143l7.784-7.783a2.262 2.262 0 000-3.2zm-.836 2.364L3.82 10.709l-2.295.766.764-2.292L9.974 1.5a1.08 1.08 0 011.528 1.526z' fill='%23#{$link-icon-color-hover}'/%3E%3C/svg%3E");
  }
}

.icon_stopwatch {
  display: inline-block;
  vertical-align: text-bottom;
  width: rem(13);
  height: rem(15);
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.3887 4.61071L11.9768 4.0226C12.2053 3.79406 12.2053 3.4235 11.9768 3.19508C11.7483 2.96654 11.3778 2.96654 11.1493 3.19508L10.5612 3.78319C9.57446 2.96104 8.37546 2.46437 7.09647 2.3481V1.17039H7.66215C7.98533 1.17039 8.24728 0.908318 8.24728 0.585136C8.24728 0.261955 7.98533 0 7.66215 0H5.3604C5.03722 0 4.77526 0.261955 4.77526 0.585136C4.77526 0.908318 5.03722 1.17039 5.3604 1.17039H5.92608V2.3481C2.72448 2.63924 0.171875 5.32883 0.171875 8.66055C0.171875 12.1643 3.00715 15 6.51133 15C10.0149 15 12.8507 12.1647 12.8507 8.66055C12.8507 7.16389 12.3352 5.74654 11.3887 4.61071ZM6.51122 13.8296C3.66106 13.8296 1.34215 11.5108 1.34215 8.66055C1.34215 5.81039 3.66106 3.49159 6.51122 3.49159C9.36148 3.49159 11.6803 5.81039 11.6803 8.66055C11.6803 11.5108 9.36148 13.8296 6.51122 13.8296ZM9.22312 5.94875C9.45166 6.17729 9.45166 6.54785 9.22312 6.77628L6.92503 9.07436C6.6965 9.3029 6.32594 9.3029 6.09751 9.07436C5.86897 8.84583 5.86897 8.47527 6.09751 8.24684L8.39548 5.94875C8.62402 5.72021 8.99458 5.72021 9.22312 5.94875Z' fill='%237F8B96'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-right: rem(5);
}
