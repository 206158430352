@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

@import url('https://fast.fonts.net/lt/1.css?apiType=css&c=afd52c43-354e-4187-b47c-df336782792d&fontids=5746790,5746845,5746866,5746814');

@font-face {
  font-family: 'avenir';
  src: url('../../fonts/5746790/d1d43d8e-e792-4a3d-aebe-2afeafdffbee.woff2') format('woff2'),
    url('../../fonts/5746790/0df04b85-219c-4fe0-9736-12c04931d434.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family:"avenir";
  src:url("../../fonts/5746814/0f3243d5-9104-45fb-aa69-48a50732fc11.woff2") format("woff2"),
    url("../../fonts/5746814/cfd7fc4b-6b4c-4276-91b4-f39ef7809e68.woff") format("woff");
  font-weight: 500; // medium
  font-style: normal;
}

@font-face {
  font-family: 'avenir';
  src: url('../../fonts/5746845/3519cb0f-02a0-41b8-b6d5-5e404cb57afb.woff2') format('woff2'),
    url('../../fonts/5746845/585a7684-d92c-459a-9111-82ebf54d6cca.woff') format('woff');
  font-weight: 600; // demi bold
}

@font-face {
  font-family: 'avenir';
  src: url('../../fonts/5746866/5685877c-e1fa-4c72-829c-2a8e19c11d06.woff2') format('woff2'),
    url('../../fonts/5746866/7cc17fdb-3606-4f7d-bad6-66ecb244f388.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
