.main {
  .app-footer {
    height: 50px;
    flex-basis: auto;
    margin-left: 0 !important;
  }
}

.main-container {
  min-height: calc(100vh - 50px);

  > .container-fluid {
    padding-left: #{$container-gap};
    padding-right: #{$container-gap};
  }
}
