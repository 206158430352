@import '@scss/_module';

.projects_table {
  color: $body-color;
  font-weight: 500;
  font-size: rem(16);
  line-height: rem(24);

  th {
    color: $table-head-color;
  }

  td {
    vertical-align: middle;
  }
}

.team_avatar {
  position: relative;
  width: rem(50);
  margin-right: rem(16);

  img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }
}

.team_title {
  display: block;
  font-weight: 600;
  // font-size: rem(13);
  line-height: 1.2307;
}

.team_name {
  color: $dovegray;
  display: block;
  font-style: inherit;
}

.link_color_inherit {
  color: inherit;
  transition: color $anim-func-base $animspeed-base;

  &:hover,
  &:focus {
    color: $blue;
    text-decoration: none;
  }
}
