@import '@scss/_module';

.icon-prev,
.icon-next {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(6);
    height: rem(10);
    margin: rem(-5) 0 0 rem(-4);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    transition: opacity $anim-func-base $animspeed-base;
  }
}

.icon-next {
  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.646 9.31L0 8.62l1.699-1.813 1.698-1.814L1.708 3.19C.778 2.198.018 1.38.018 1.373.018 1.356 1.288 0 1.304 0c.007 0 1.066 1.125 2.354 2.5L6 5 3.658 7.5A325.855 325.855 0 011.304 10c-.007 0-.303-.31-.658-.69z' fill='%23#{$icon-color-base}'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.646 9.31L0 8.62l1.699-1.813 1.698-1.814L1.708 3.19C.778 2.198.018 1.38.018 1.373.018 1.356 1.288 0 1.304 0c.007 0 1.066 1.125 2.354 2.5L6 5 3.658 7.5A325.855 325.855 0 011.304 10c-.007 0-.303-.31-.658-.69z' fill='%23#{$icon-color-blue}'/%3E%3C/svg%3E");
  }
}

.icon-prev {
  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.354.69L6 1.38 4.301 3.192 2.603 5.007 4.292 6.81c.93.992 1.69 1.81 1.69 1.817 0 .017-1.27 1.373-1.286 1.373-.007 0-1.066-1.125-2.354-2.5L0 5l2.342-2.5C3.63 1.125 4.689 0 4.696 0c.007 0 .303.31.658.69z' fill='%23#{$icon-color-base}'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.354.69L6 1.38 4.301 3.192 2.603 5.007 4.292 6.81c.93.992 1.69 1.81 1.69 1.817 0 .017-1.27 1.373-1.286 1.373-.007 0-1.066-1.125-2.354-2.5L0 5l2.342-2.5C3.63 1.125 4.689 0 4.696 0c.007 0 .303.31.658.69z' fill='%23#{$icon-color-blue}'/%3E%3C/svg%3E");
  }
}

.link-back {
  color: $regentgray;
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  font-size: rem(16);
  line-height: rem(24);
  padding-left: rem(25);
  transition: color $anim-func-base $animspeed-base;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: rem(16);
    height: rem(11);
    margin-top: rem(-5);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.685 4.702L5.009.374a.675.675 0 11.956.956L2.794 4.504h11.549a.676.676 0 010 1.352H2.794l3.17 3.174a.677.677 0 01-.955.956L.685 5.658a.677.677 0 010-.956z' fill='%237F8B96'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-right: rem(10);
    transition: opacity $anim-func-base $animspeed-base;
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.685 4.702L5.009.374a.675.675 0 11.956.956L2.794 4.504h11.549a.676.676 0 010 1.352H2.794l3.17 3.174a.677.677 0 01-.955.956L.685 5.658a.677.677 0 010-.956z' fill='%23009bff'/%3E%3C/svg%3E");
    opacity: 0;
  }

  &:hover,
  &:focus {
    color: $blue;
    text-decoration: none;

    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }
  }
}
