@import '@scss/_module';

// html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
// html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer,
// html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
// html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {

// }

.sidebar--main + {
  .main {
    // transition: none;
    margin-left: $sidebar-primary-width !important;
  }
}

.navbar-brand {
  display: block;
  padding: rem(17) rem(16) rem(13) !important;
  margin: 0 0 rem(6);

  @media (hover: hover) {
    @include media-breakpoint-up(md) {
      .navbar-brand-name {
        opacity: 0;
        transition: opacity $animspeed-base;

        .sidebar:hover & {
          opacity: 1;
        }
      }
    }
  }
}

.modal-open {
  overflow: inherit;
}

.sidebar {
  color: $white;
  height: 100%;
  line-height: 1.5;
  letter-spacing: rem(0.214286);
  overflow: hidden;

  .nav {
    min-height: auto;
    padding-left: rem(8);
    padding-right: rem(8);
    overflow: hidden;
  }

  .nav-item {
    position: static;
    margin-top: rem(10);

    &:first-child {
      margin-top: 0;
    }
  }

  .nav-link {
    display: block;
    cursor: pointer;

    strong {
      font-weight: inherit;
      transition: opacity $animspeed-base;
    }
  }

  .nav-icon {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: rem(14) !important;
    height: rem(17) !important;
    background-repeat: no-repeat;
    background-position: 50% 100%;
  }

  .badge {
    border-radius: 50%;
    position: absolute;
    top: rem(7);
    left: rem(18);
    right: auto !important;
    // margin-left: rem(-4);

    .sidebar-minimized & {
      display: block;
    }
  }

  @include media-breakpoint-up(md) {
    html:not([dir='rtl']) .sidebar-lg-show &,
    html:not([dir='rtl']) .sidebar-show & {
      margin-left: 0;
    }
  }
}

.sidebar--main {
  .sidebar-nav {
    position: relative;
    overflow: hidden;

    .nav-item,
    .nav-link,
    .nav-link::after {
      width: 100%;
    }

    .nav-link {
      z-index: 1;
      padding: rem(7) rem(12) rem(10);
      white-space: nowrap;
      background: transparent !important;

      &::after {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: rem(38);
        height: 100%;
        border-radius: 100px;
        background: $sidebar-nav-link-hover-bg;
        transition: width $animspeed-base, opacity $animspeed-base;
        opacity: 0;

        .sidebar:hover & {
          width: 100%;
        }
      }

      &.active,
      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  @media (hover: hover) {
    @include media-breakpoint-up(md) {
      &,
      .sidebar-fixed & {
        width: $sidebar-primary-width !important;

        &:hover {
          width: $sidebar-width !important;
        }
      }

      .nav {
        width: $sidebar-primary-width !important;

        .nav-item {
          width: 42px;
        }
      }

      &:hover {
        .nav {
          width: $sidebar-width !important;
        }

        .nav-item,
        .nav-link,
        .nav-link::after {
          width: 100% !important;
        }
      }
    }
  }
}

.sidebar--secondary {
  z-index: 1018 !important;

  @include media-breakpoint-up(md) {
    background: $heavymetal;
    padding-right: 20px;

    html:not([dir='rtl']) & {
      left: $sidebar-primary-width !important;
      width: #{$sidebar-width - $sidebar-primary-width};
    }

    &.sidebar .sidebar-minimizer {
      z-index: 10;
      position: absolute;
      bottom: 0;
      right: 0;
      left: auto;
      width: $sidebar-minimized-width;
      height: 100%;
      padding-left: rem(2);
      padding-right: rem(2);
      background: $sidebar-minimizer-bg;

      &:hover {
        background: $sidebar-minimizer-hover-bg;
      }
    }

    .sidebar-nav,
    .nav {
      width: 100%;
    }

    .nav-item {
      transition: opacity $animspeed-base, background-color $animspeed-base;
    }

    .sidebar-minimized & .nav-item {
      opacity: 0;
    }
  }
}

@media (hover: hover) {
  @include media-breakpoint-up(md) {
    html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main,
    html:not([dir='rtl']) .sidebar-show.sidebar-fixed.sidebar-minimized .main {
      margin-left: 74px !important;
    }
  }
}

@include media-breakpoint-up(md) {
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main {
    margin-left: $sidebar-width;
  }

  .app-header,
  .sidebar,
  .main,
  .aside-menu {
    transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
  }

  .sidebar-minimizer {
    display: block;

    .sidebar-minimized .sidebar &:before {
      width: 100%;
      transform: rotate(-180deg);
    }
  }

  .sidebar-minimized.sidebar-fixed .sidebar {
    width: $sidebar-minimized-width;
  }
}

.nav-icon {
  margin-right: rem(20) !important;
}

.nav-link {
  position: relative;
  transition: color $animspeed-base, background-color $animspeed-base !important;

  transition: background-color 0.25s linear;
  padding: rem(14) rem(16);
  font-weight: 400;

  .sidebar--secondary .nav & {
    font-weight: 500;
    padding: rem(7) rem(3) rem(8) rem(10);
    background: transparent;
    border-radius: rem(4);
    white-space: nowrap;

    &:hover,
    &.active {
      background: $outerspace;
    }

    .nav-icon {
      display: none;
    }
  }

  // &:hover,
  // &.active {
  //   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  // }

  // .nav-dropdown-items & {
  //   padding-left: rem(18) !important;
  // }
}

// settings
.dropdown-center {
  .sidebar-minimized & .nav-item {
    width: $sidebar-primary-width;
  }

  .sidebar & .nav-link {
    padding: 0;
    border-radius: 0;
    transition: all $animspeed-base;
  }

  .sidebar:hover & .nav-link {
    background: $black;
  }

  .dropdown-menu {
    max-width: 100%;
    left: -5px !important;
    padding-left: rem(12);
    padding-right: rem(12);
    background-color: transparent;
    opacity: 0;
    visibility: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: rem(12);
      right: rem(12);
      height: 100%;
      background-color: $white;
      border-radius: $dropdown-border-radius;
    }

    .sidebar:hover & {
      opacity: 1;
      visibility: visible;
    }
  }

  .dropdown-item {
    padding: rem(7) rem(14) rem(6);
    line-height: rem(20);
    border: 0;
    transition: $btn-transition;

    i {
      margin-left: rem(-8);
      margin-right: rem(5);
    }
  }
}

.sidebar__title {
  padding: rem(14) rem(4) rem(12) rem(16);
  border-bottom: 1px solid $outerspace;
  margin-bottom: rem(10);

  h2 {
    color: rgba($white, 0.75);
    font-size: rem(13);
    line-height: rem(18);
    margin: 0;
  }

  h3 {
    color: $white;
    font-weight: 500;
    font-size: rem(18);
    line-height: rem(25);
    letter-spacing: rem(0.214286);
    margin: 0;
  }
}
