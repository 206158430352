@import '@scss/_module';

.link_filter {
  color: $codgray;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border: 1px solid $geyser !important;
  padding: rem(12) rem(16);
  border-radius: $border-radius !important;
  border: 1px solid $geyser;
  transition: opacity $anim-func-base $animspeed-base, background-color $anim-func-base $animspeed-base;
  font-weight: 500;
  // font-size: rem(13);
  line-height: 1;

  &:hover,
  &:focus {
    color: $codgray;
    background: $aquahaze;
    text-decoration: none;
  }
}

.icon_filter  {
  display: inline-block;
  vertical-align: top;
  width: rem(16);
  height: rem(14);
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.621 1.28H5.556A1.874 1.874 0 003.783 0C2.96 0 2.26.537 2.01 1.28H.595A.596.596 0 000 1.878c0 .33.267.598.595.598H2.01c.25.743.95 1.28 1.773 1.28s1.523-.537 1.773-1.28h9.066a.596.596 0 00.595-.598.597.597 0 00-.596-.598zM3.784 2.56a.682.682 0 010-1.365.682.682 0 010 1.366zM14.621 6.403h-1.415a1.874 1.874 0 00-1.772-1.28c-.823 0-1.524.536-1.773 1.28H.595A.596.596 0 000 7c0 .33.267.598.595.598h9.066c.25.743.95 1.28 1.773 1.28s1.523-.537 1.772-1.28h1.416A.596.596 0 0015.217 7a.597.597 0 00-.596-.597zm-3.187 1.28a.682.682 0 010-1.365.682.682 0 010 1.365zM14.621 11.525H8.107a1.874 1.874 0 00-1.773-1.28c-.823 0-1.523.536-1.772 1.28H.595a.596.596 0 00-.595.597c0 .33.267.598.595.598h3.966c.249.743.949 1.28 1.772 1.28.824 0 1.524-.537 1.773-1.28h6.516a.596.596 0 00.595-.598.596.596 0 00-.596-.597zm-8.288 1.28a.682.682 0 010-1.365.682.682 0 010 1.365z' fill='%237F8B96'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-right: rem(4);
}

.show {
  display: block;
  opacity: 1;
  visibility: visible;
}

.filter_headline {
  color: $codgray;
  padding-bottom: rem(18);
  margin-bottom: rem(18);
  border-bottom: 1px solid $dropdown-divider-bg;
}

.filter_heading {
  white-space: nowrap;
  font-weight: 500;
  font-size: rem(18);
  line-height: rem(22);
  margin-right: rem(10);
}

.filter_title {
  color: $regentgray;
  display: block;
  font-weight: inherit;
  font-size: rem(12);
  line-height: 1;
  margin-bottom: rem(14);
}
