@import '@scss/_module';

.tag {
  // min-width: rem(120);
  color: $blue;
  background: $bg-dev;
  display: inline-block;
  vertical-align: middle;
  // font-size: rem(12);
  // line-height: 1;
  padding: rem(4) rem(17) rem(6);
  text-align: center;
  border-radius: rem(14);
  font-weight: 600;
}

.tag--strategy {
  color: $purple;
  background: rgba($lavenderblush, 0.5);
}

.tag--design {
  color: $cerisered;
  background: rgba($bg-des, 0.5);
}

.tag--marketing {
  color: $orange;
  background: rgba($bg-mark, 0.5);
}

.tag--production {
  color: $gold;
  background: $bg-prod;
}

.tag--technology {
  color: $turquoise;
  background: $bg-tech;
}

.team-icon {
  span {
    display: block;
    width: rem(32);
    height: rem(32);
    text-align: center;
    border-radius: 50%;

    i {
      width: rem(18);
      height: rem(18);
      display: inline-block;
      vertical-align: middle;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
  }

  &.color--strategy {
    span {
      background: $bg-dev;
    }

    i {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M11.881 8.131l-9.906-3.81 9.906-3.048 9.906 3.81v12.192l-9.906 4.572V8.131z' fill='%23C6E8FF'/%3E%3Cpath d='M11.736 8.74L2.12 5.17 1.8 17.498l9.936 3.244V8.74z' fill='%23fff'/%3E%3Cpath d='M21.805 3.709L12.013.34a1.349 1.349 0 00-.602 0L1.614 3.713c-.798.249-.776.885-.776.903v12.962a.9.9 0 00.602.845l9.971 3.896c.178.077.557.045.65 0l9.972-3.892a.9.9 0 00.602-.844V4.616c0-.28-.17-.821-.83-.907zM11.736 2.174l7.328 2.487-7.328 2.822L4.41 4.661l7.327-2.487zm-9.088 14.75V5.98l8.161 3.147v10.989l-8.16-3.192zm18.177 0l-8.161 3.192V9.154l8.16-3.174v10.944z' fill='%23009BFF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' transform='translate(.97 .307)' d='M0 0h22.059v22.059H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }

  &.color--design {
    span {
      background: $bg-des;
    }

    i {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cellipse cx='11.846' cy='10.87' rx='3.178' ry='3.116' fill='%23F1C0CA'/%3E%3Cpath fill='%23F1C0CA' d='M7.079 17.102h9.535v3.116H7.079z'/%3E%3Cpath d='M11.692 8.88l-.338-5.57-.338-.984-5.75 9.503 3.044 5.57h7.103l3.383-5.898-6.427-8.52V8.88l1.015 1.966-.339.983-1.69.327-.677-.327V10.19l1.014-1.31z' fill='%23fff'/%3E%3Cpath d='M19.455 11.238L12.635.425a.856.856 0 00-1.438 0l-6.82 10.813a.813.813 0 00-.016.843l2.857 4.942a2.475 2.475 0 00-1.276 2.154c0 1.368 1.137 2.482 2.536 2.482h6.82c1.4 0 2.592-1.109 2.592-2.482 0-.915-.527-1.722-1.322-2.158l2.9-4.933a.812.812 0 00-.013-.848zm-7.54-1.22c.467 0 .846.371.846.828 0 .456-.38.827-.845.827a.838.838 0 01-.846-.827c0-.457.38-.828.846-.828zm3.382 9.986h-6.82a.838.838 0 01-.845-.828c0-.455.379-.826.845-.827h6.82c.48 0 .902.387.902.828 0 .44-.421.827-.902.827zm-.489-3.31H8.971L6.078 11.69l4.992-7.915v4.73c-.984.34-1.69 1.26-1.69 2.34 0 1.369 1.137 2.482 2.536 2.482 1.398 0 2.536-1.113 2.536-2.482 0-1.08-.707-2-1.69-2.34v-4.73l4.99 7.912-2.944 5.006z' fill='%23DF425A'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' transform='translate(.97 .033)' d='M0 0h22.059v21.626H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }

  &.color--development {
    span {
      background: $bg-dev;
    }

    i {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M11.881 8.131l-9.906-3.81 9.906-3.048 9.906 3.81v12.192l-9.906 4.572V8.131z' fill='%23C6E8FF'/%3E%3Cpath d='M11.736 8.74L2.12 5.17 1.8 17.498l9.936 3.244V8.74z' fill='%23fff'/%3E%3Cpath d='M21.805 3.709L12.013.34a1.349 1.349 0 00-.602 0L1.614 3.713c-.798.249-.776.885-.776.903v12.962a.9.9 0 00.602.845l9.971 3.896c.178.077.557.045.65 0l9.972-3.892a.9.9 0 00.602-.844V4.616c0-.28-.17-.821-.83-.907zM11.736 2.174l7.328 2.487-7.328 2.822L4.41 4.661l7.327-2.487zm-9.088 14.75V5.98l8.161 3.147v10.989l-8.16-3.192zm18.177 0l-8.161 3.192V9.154l8.16-3.174v10.944z' fill='%23009BFF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' transform='translate(.97 .307)' d='M0 0h22.059v22.059H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }
}

.team-ava {
  position: relative;
  width: rem(50);
  height: rem(50);

  svg {
    position: relative;
    z-index: 10;
    display: block;
  }

  img {
    vertical-align: top;
  }
}

// .table-height-auto {
//   .react-bs-table,
//   .react-bs-container-body {
//     overflow-y: hidden;
//     overflow-x: auto;
//   }
// }

.react-bs-container-body {
  overflow-y: hidden;
  overflow-x: auto;
}

.svg {
  display: block;
  margin: 20px auto;
  max-width: 100%;
}

.svg-circle-bg {
  fill: none;
}

.svg-circle {
  fill: none;
}

.svg-circle-text {
  font-size: 2rem;
  text-anchor: middle;
  fill: #fff;
  font-weight: bold;
}

.note-tooltip {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: rem(16);
  height: rem(16);
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #F34443;
  background: rgba(243, 68, 67, 0.15);
  text-indent: -999px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: rem(-5) 0 0 rem(-1);
    width: rem(2);
    height: rem(10);
    background-image: url("data:image/svg+xml,%3Csvg width='2' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.78 6.948H.22V.714h1.56v6.234zM2 9.065c0 .251-.09.467-.273.65a.97.97 0 01-.714.285.994.994 0 01-.714-.273A.839.839 0 010 9.065c0-.268.1-.494.299-.675.19-.174.428-.26.714-.26s.524.09.714.273A.93.93 0 012 9.065z' fill='%23F34443'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.page-link {
  font-size: rem(12);
  font-weight: 500;
  padding-top: rem(7);
  padding-bottom: rem(9);
}
