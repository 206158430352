// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
) !default;

// Variable overrides
$white: #fff;
$black: #000;
$pink: #ffecee;
// $red: #eb5757 !default; // notif
$red: #f86c6b !default;
$slategray: #708393;
$regentgray: #7f8b96;
$geyser: #d4dee5;
$mischka: #cbd3db;
$catskillwhite: #f1f5f8;
$dovegray: #717171;
$blackhaze: #f2f4f4;
$shuttlegray: #566068;
$tundora: #444;

$loblolly: #c2cad0;
$silversand: #c0c2c4;
$blacksqueeze: #f7fafc;

$base-background-color: #fff;
$outerspace: #2e343a;
$mirage: #182025;
$codgray: #151515;
$aquahaze: #f4f7f8;
$iron: #dfe1e1;
$heavymetal: #202121;
$geyser: #d5dfe6;
$flamingo: #f34443;

$aliceblue: #f0f9ff;

$navbar-bg: $outerspace;
$navbar-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $mirage,
  ),
) !default;

$body-bg: $blacksqueeze !default;
$body-color: #2e353a !default;
$min-width-base: 320px;
$font-size-base: 0.8125rem !default; // 13px

// Primary Colors
$blue: #009bff; // Brand | Development
$turquoise: #1dc4d7; // Technology
$purple: #6b00ff; // Strategy
$cerisered: #df425a; // Design
$orange: #fa7548; // Marketing
$gold: #feb12b; // Production

// Secondary Colors
$sky-blue: #c6e8ff;
$paleturquoise: #c6eef2;
$lavenderblush: #e1ccff;
$mistyrose: #f1c0ca;
$seashell: #fed5c6;
$moccasin: #fae2b1;

$bg-dev: $sky-blue;
$bg-tech: $paleturquoise;
$bg-strat: $lavenderblush;
$bg-des: $mistyrose;
$bg-mark: $seashell;
$bg-prod: $moccasin;

$lightblue: #e2f3ff;

$footer-bg: $white !default;
$footer-borders: (
  top: (
    size: 2px,
    style: solid,
    color: $geyser,
  ),
) !default;

$navbar-color: #151515 !default;
$navbar-hover-color: $black !default;

$nav-icon-color: 'ffffff' !default;

$link-icon-color: '7f8b96' !default;
$link-icon-color-hover: '7f8b96' !default;

$line-height-base: 1.3846155 !default; // 1.71429

$primary: $blue !default;
$secondary: $geyser !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
$danger: $flamingo !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;

$border-color: #d6d6d6;
$bg-th: #f0f0f0;
$white: white;
// Sidebar Navigation

$sidebar-nav-link-color: $white !default;

$sidebar-nav-link-hover-bg: $outerspace !default;
$sidebar-nav-link-active-bg: $outerspace !default;

$font-base: 'avenir', Arial, Helvetica, sans-serif;
$font-alt: 'Nunito Sans', Arial, Helvetica, sans-serif;

// animation

$anim-func-base: linear;
$animspeed-base: 0.25s;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-x: 0.75rem !default;
$input-btn-line-height: 1.5385 !default; // 1.71429

$input-font-size: 0.8125rem !default;
$input-font-weight: 500 !default;

$border-radius: 0.25rem !default; // 0.1875rem

$input-height: 2.5rem !default;

// $input-focus-bg:                        $input-bg !default;
// $input-focus-border-color:              lighten($component-active-bg, 25%) !default;
// $input-focus-color:                     $input-color !default;
// $input-focus-width:                     $input-btn-focus-width !default;
// $input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 0.5rem !default;
$btn-padding-x: 1.8571rem !default;
// $btn-font-family:             $input-btn-font-family !default;
$btn-font-size: 0.8125rem !default;
$btn-line-height: $input-btn-line-height !default;

// $btn-padding-y-sm:            $input-btn-padding-y-sm !default;
// $btn-padding-x-sm:            $input-btn-padding-x-sm !default;
// $btn-font-size-sm:            $input-btn-font-size-sm !default;
// $btn-line-height-sm:          $input-btn-line-height-sm !default;

// $btn-padding-y-lg:            $input-btn-padding-y-lg !default;
// $btn-padding-x-lg:            $input-btn-padding-x-lg !default;
// $btn-font-size-lg:            $input-btn-font-size-lg !default;
// $btn-line-height-lg:          $input-btn-line-height-lg !default;

// $btn-border-width:            $input-btn-border-width !default;

$btn-font-weight: 600 !default;
// $btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
// $btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow: none !default;
$btn-disabled-opacity: 0.25 !default;
$btn-active-box-shadow: none !default;

// $btn-link-disabled-color:     $gray-600 !default;

// $btn-block-spacing-y:         .5rem !default;
$btn-border-radius: $border-radius !default;

$icon-color-base: '151515';
$icon-color-secondary: 'D5DFE6';
$icon-color-active: 'ffffff';

$icon-color-sidebar: 'ffffff';
$icon-color-drop: '566068';

$icon-color-blue: '009BFF';

$btn-transition: color $anim-func-base $animspeed-base, background-color $anim-func-base $animspeed-base,
  border-color $anim-func-base $animspeed-base;

// Forms

$input-color: $codgray !default;
$input-placeholder-color: $silversand;
$input-bg: $white !default;
$input-border-color: $geyser !default;
$input-focus-border-color: $blue !default;
$alt-input-bg: $aquahaze !default;
$alt-input-placeholder-color: $shuttlegray !default;

// Form validation

$form-feedback-valid-color: $input-border-color !default;

$flag-bg-color: #404249;

$form-check-inline-margin-x: 0, 5rem;

// functions
@function rem($target, $context: 16) {
  @return ($target / $context) * 1rem;
}

// toolbar

$toolbar-color: $slategray;
$toolbar-bg-color: $mirage;

$progress-bg-base: $catskillwhite;
$progress-bg-accent: $blue;

// tooltip
$tooltip-bg-color: #fefefe;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// $table-cell-padding:          .75rem !default;
// $table-cell-padding-sm:       .3rem !default;

// $table-color:                 $body-color !default;
$table-bg: transparent !default;
// $table-accent-bg:             rgba($black, .05) !default;
// $table-hover-color:           $table-color !default;
$table-hover-bg: transparent !default;
$table-active-bg: $table-hover-bg !default;

$table-border-radius: 14px !default;
$table-border-width: 1px !default;
$table-border-color: $geyser !default;

$toolbar-border-color: $geyser !default;

// $table-head-bg:               $regentgray !default;
$table-head-color: $shuttlegray !default;

// $table-dark-color:            $white !default;
// $table-dark-bg:               $gray-800 !default;
// $table-dark-accent-bg:        rgba($white, .05) !default;
// $table-dark-hover-color:      $table-dark-color !default;
// $table-dark-hover-bg:         rgba($white, .075) !default;
// $table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
// $table-dark-color:            $white !default;

// $table-striped-order:         odd !default;

// $table-caption-color:         $text-muted !default;

// $table-bg-level:              -9 !default;
// $table-border-level:          -6 !default;

// Sidebar

$sidebar-width: 260px !default;
$sidebar-primary-width: 54px !default;
// $sidebar-padding:                     0 !default;
$sidebar-minimized-width: 20px !default;
// $sidebar-minimized-height:            $sidebar-minimized-width !default;
// $sidebar-compact-width:               150px !default;
// $sidebar-compact-height:              $sidebar-compact-width !default;
// $sidebar-color:                       #fff !default;
$sidebar-bg: $codgray !default;
// $sidebar-borders:                     none !default;
// $mobile-sidebar-width:                220px !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 100% !default;
// $dropdown-padding-y:                .5rem !default;
// $dropdown-spacer:                   .125rem !default;
// $dropdown-font-size:                $font-size-base !default;
// $dropdown-color:                    $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: $geyser !default;
$dropdown-border-radius: 0.25rem !default;
$dropdown-border-width: 0 !default;
$dropdown-inner-border-radius: 0.25rem !default;
$dropdown-divider-bg: $dropdown-border-color !default;
// $dropdown-divider-margin-y:         $nav-divider-margin-y !default;
$dropdown-box-shadow: 10px 30px 60px rgba(56, 87, 104, 0.15) !default;

$dropdown-link-color: $codgray !default;
$dropdown-link-hover-color: $codgray !default;
$dropdown-link-hover-bg: $aquahaze !default;

$dropdown-link-active-color: $codgray !default;
$dropdown-link-active-bg: $aquahaze !default;

// $dropdown-link-disabled-color:      $gray-600 !default;

// $dropdown-item-padding-y:           .25rem !default;
// $dropdown-item-padding-x:           1.5rem !default;

$dropdown-header-color: $regentgray !default;

// select
// $custom-select-padding-y:           $input-padding-y !default;
// $custom-select-padding-x:           $input-padding-x !default;
// $custom-select-font-family:         $input-font-family !default;
$custom-select-font-size: rem(13) !default;
$custom-select-height: $input-height !default;
// $custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: 600 !default; //$input-font-weight !default;
// $custom-select-line-height:         $input-line-height !default;
$custom-select-color: $regentgray !default;
// $custom-select-disabled-color:      $gray-600 !default;
$custom-select-bg: $white !default;
// $custom-select-disabled-bg:         $gray-200 !default;
// $custom-select-bg-size:             8px 10px !default; // In pixels because image dimensions
// $custom-select-indicator-color:     $gray-800 !default;
// $custom-select-indicator:           str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"), "#", "%23") !default;
// $custom-select-background:          $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

// $custom-select-feedback-icon-padding-right: calc((1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x + $custom-select-indicator-padding}) !default;
// $custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
// $custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half !default;

// $custom-select-border-width:        $input-border-width !default;
$custom-select-border-color: $geyser !default;
// $custom-select-border-radius:       $border-radius !default;
// $custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075) !default;

// $custom-select-focus-border-color:  $input-focus-border-color !default;
// $custom-select-focus-width:         $input-focus-width !default;
// $custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color !default;

// $custom-select-padding-y-sm:        $input-padding-y-sm !default;
// $custom-select-padding-x-sm:        $input-padding-x-sm !default;
// $custom-select-font-size-sm:        $input-font-size-sm !default;
// $custom-select-height-sm:           $input-height-sm !default;

// $custom-select-padding-y-lg:        $input-padding-y-lg !default;
// $custom-select-padding-x-lg:        $input-padding-x-lg !default;
// $custom-select-font-size-lg:        $input-font-size-lg !default;
// $custom-select-height-lg:           $input-height-lg !default;

// Badges

$badge-font-size: rem(8) !default;
$badge-font-weight: 600 !default;
$badge-padding-y: rem(1) !default;
$badge-padding-x: rem(3) !default;
$badge-border-radius: 50% !default;

// $badge-transition:                  $btn-transition !default;
// $badge-focus-width:                 $input-btn-focus-width !default;

// $badge-pill-padding-x:              .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;

$sidebar-minimizer-height: 100% !default;
$sidebar-minimizer-bg: $heavymetal !default;
$sidebar-minimizer-borders: 0 !default;
// $sidebar-minimizer-indicator-color:   $gray-600 !default;
// $sidebar-minimizer-indicator:         str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;
$sidebar-minimizer-hover-bg: $sidebar-bg !default;
// $sidebar-minimizer-hover-indicator-color:$sidebar-nav-link-hover-color !default;
// $sidebar-minimizer-hover-indicator:   str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-hover-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23") !default;

// icons
$little-arrow: url("data:image/svg+xml,%3Csvg width='10' height='5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.013 4.75l-4.5-4h9l-4.5 4z' fill='%23fff'/%3E%3C/svg%3E");

$calendar-icon: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.5 4.4097C0.5 3.13414 1.53404 2.1001 2.8096 2.1001H13.5904C14.866 2.1001 15.9 3.13414 15.9 4.4097V15.1905C15.9 16.4661 14.866 17.5001 13.5904 17.5001H2.8096C1.53404 17.5001 0.5 16.4661 0.5 15.1905V4.4097ZM2.8096 3.1001C2.08633 3.1001 1.5 3.68643 1.5 4.4097V15.1905C1.5 15.9138 2.08633 16.5001 2.8096 16.5001H13.5904C14.3137 16.5001 14.9 15.9138 14.9 15.1905V4.4097C14.9 3.68643 14.3137 3.1001 13.5904 3.1001H2.8096Z' fill='%2375767C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.3984 0.5C11.6746 0.5 11.8984 0.723858 11.8984 1V4.2C11.8984 4.47614 11.6746 4.7 11.3984 4.7C11.1223 4.7 10.8984 4.47614 10.8984 4.2V1C10.8984 0.723858 11.1223 0.5 11.3984 0.5Z' fill='%2375767C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 0.5C5.27614 0.5 5.5 0.723858 5.5 1V4.2C5.5 4.47614 5.27614 4.7 5 4.7C4.72386 4.7 4.5 4.47614 4.5 4.2V1C4.5 0.723858 4.72386 0.5 5 0.5Z' fill='%2375767C'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.5 7.3999C0.5 7.12376 0.723858 6.8999 1 6.8999H15.4C15.6761 6.8999 15.9 7.12376 15.9 7.3999C15.9 7.67604 15.6761 7.8999 15.4 7.8999H1C0.723858 7.8999 0.5 7.67604 0.5 7.3999Z' fill='%2375767C'/%3e%3c/svg%3e") !default;

// Breakpoints for mobile
$breakpoint-mobile-up: md !default;
$breakpoint-mobile-down: sm !default;

$container-gap: 28px;

// headings
$h1-font-size: $font-size-base * 1.84615 !default;
$h2-font-size: $font-size-base * 1.53846 !default;
$h3-font-size: $font-size-base * 1.3846 !default;
$h4-font-size: $font-size-base * 1.07692 !default;
$h5-font-size: $font-size-base !default;
$h6-font-size: $font-size-base !default;

// $headings-margin-bottom:      $spacer / 2 !default;
$headings-font-family: $font-base !default;
$headings-font-weight: 700 !default;

// Cards

$card-spacer-y: 1rem !default;
$card-spacer-x: 1.875rem !default; // 2.25rem
$card-border-width: 0 !default;
$card-border-radius: rem(8) !default;
// $card-border-color:                 rgba($black, .125) !default;
$card-inner-border-radius: $card-border-radius !default;
$card-cap-bg: $white !default;
// $card-cap-color:                    null !default;
// $card-color:                        null !default;
// $card-bg:                           $white !default;

// $card-img-overlay-padding:          1.25rem !default;

// $card-group-margin:                 $grid-gutter-width / 2 !default;
// $card-deck-margin:                  $card-group-margin !default;

// $card-columns-count:                3 !default;
// $card-columns-gap:                  1.25rem !default;
// $card-columns-margin:               $card-spacer-y !default;
