@import '@scss/_module';

.project_headline {
  margin-top: rem(-8);
}

.project_info {
  display: flex;
  justify-content: flex-start;

  &,
  a {
    color: $regentgray;
  }

  a {
    transition: color $anim-func-base $animspeed-base;

    &:hover,
    &:focus {
      color: $blue;
      text-decoration: none;
    }
  }

  span:nth-of-type(2) {
    border-left: 1px solid;
    padding-left: 30px;
  }
}

.icon_flex_container {
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}
