.top-panel {
  margin-left: -$container-gap;
  margin-right: -$container-gap;
  background-color: $white;
  // margin-bottom: rem(14);

  select,
  .Select {
    margin-bottom: 0;
  }
}

.top-bar {
  padding-left: $container-gap;
  padding-right: $container-gap;
  border-bottom: 1px solid $toolbar-border-color;
}

.top-bar {
  padding-top: rem(26);
  padding-bottom: rem(9); // rem(24)
}
