dl {
  color: $regentgray;
  margin: 0;
  // font-size: rem(13);
  line-height: rem(16);

  dt {
    padding-right: rem(6);
  }

  dt,
  dd {
    margin: 0;
    font-weight: 500;
    padding-top: rem(12);
    padding-bottom: rem(12);
  }

  &:last-child {
    color: $body-color;
  }
}
